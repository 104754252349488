import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Work Sans',
  h1: {
    fontSize: '20px',
    fontFamily: 'Barlow',
    fontWeight: 600, // bold
    lineHeight: 1.33,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  h2: {
    fontSize: '14px',
    fontWeight: 600, // bold
    lineHeight: 1.2,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  h3: {
    fontSize: '12px',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  h4: {
    fontSize: '11px ',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontFamily: 'Work Sans',
  },
  h5: {
    fontSize: '14px',
    fontWeight: 700, // bold
    lineHeight: 1.25,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  h6: {
    fontSize: '10px',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  body1: {
    fontFamily: 'Barlow',
    fontSize: '0.75rem', // 12px
    fontWeight: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  caption: {
    // Hint text
    fontSize: '0.75rem', // 12px
    fontWeight: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    fontStretch: 'normal',
  },
  subtitle1: {
    fontFamily: 'Work Sans',
    fontSize: '0.75rem', // 12px
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
  subtitle2: {
    fontFamily: 'Barlow',
    fontSize: '0.75rem', // 12px
    fontWeight: 'normal',
    lineHeight: 1,
  },
  button: {
    fontSize: '16px',
    fontWeight: 500,
  },
};

export default typography;
