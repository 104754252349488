import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { useAuthenticationService, useFeatureToggleV2 } from 'admin-portal-shared-services';
import { useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { EnvConfig, EnvProvider, SidebarProvider } from './components';
import useAppConfig from './hooks/useAppConfig/useAppConfig';
import useCanaryConfig from './hooks/useCanaryConfig/useCanaryConfig';
import Routes from './Routes';
import defaultTheme from './themes/Theme';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './utils/constants';
import { TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED } from './utils/featureToggles';

type AppProps = EnvConfig & { TLP_SEGMENT_KEY: string };

export default function App(props: AppProps) {
  useAppConfig(props);
  const { setIsCanary, isLoaded } = useCanaryConfig({ optKey: props.ONCUSTOMER_OPTIMIZELY_KEY });
  const authentication = useAuthenticationService();

  const countryB2C = authentication.getCountryB2C();
  const emailB2C = authentication.getUserEmailB2C();

  useMemo(() => {
    (async () => {
      await setIsCanary(emailB2C, countryB2C);
    })();
  }, [setIsCanary, countryB2C, emailB2C]);

  useFeatureToggleV2(TLP_CHECK_FEATURE_TOGGLES_ARE_LOADED, WRAPPER_PROJECT_NAME_INSTANCE);

  const generateClassName = createGenerateClassName({
    productionPrefix: 'grow-portal-main-mfe',
    seed: 'grow-portal-main-mfe',
  });

  return (
    <CookiesProvider>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={defaultTheme}>
          <SidebarProvider>
            <EnvProvider env={props}>
              <CssBaseline />
              <BrowserRouter>
                <Routes isLoaded={isLoaded} />
              </BrowserRouter>
            </EnvProvider>
          </SidebarProvider>
        </ThemeProvider>
      </StylesProvider>
    </CookiesProvider>
  );
}
