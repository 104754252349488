import styled from 'styled-components';

const Routes = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
`;

export default {
  Routes,
};
