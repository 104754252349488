import { styled } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { IconProps } from './IconProps.types';

const TINY = 'tiny';
const MEDIUM = 'medium';
const XLARGE = 'xlarge';

export const Svg = styled(({ ...rest }: IconProps) => <SvgIcon {...rest} />)({
  width: (props: IconProps) => {
    switch (props.size) {
      case TINY:
        return 12;
      case MEDIUM:
        return 16;
      case XLARGE:
        return 32;
      default:
        return 24;
    }
  },
  height: (props: IconProps) => {
    switch (props.size) {
      case TINY:
        return 12;
      case MEDIUM:
        return 16;
      case XLARGE:
        return 32;
      default:
        return 24;
    }
  },
});
