import { createContext, useContext } from 'react';

export type AppTranslations = {
  'en-US': string;
  'es-419': string;
  'pt-BR': string;
};

export type AppsConfiguration = {
  id: string;
  appName: AppTranslations;
  appDescription: AppTranslations;
  appScopePrefix: string;
  baseRoute: string;
  homeRoute: string;
  hidden: boolean;
};

export type EnvConfig = {
  segmentKey: string;
  ONCUSTOMER_OPTIMIZELY_KEY: string;
  homeRoute: string;
};

const defaultInitialValue: EnvConfig = {
  segmentKey: '',
  ONCUSTOMER_OPTIMIZELY_KEY: '',
  homeRoute: '/tlp/home',
};

export const EnvContext = createContext(defaultInitialValue);

export const useEnvContext = () => useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);
