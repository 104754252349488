export const UPDATE_LOCAL_STORAGE_LOGOUT: string[] = [
  'authTimestamp',
  'ajs_user_traits',
  'ajs_group_properties',
  'ajs_user_id',
  'ajs_anonymous_id',
  'ajs_group_id',
  'portal-config',
  'authHeader',
  'refresh_token',
  'refresh_token_expires_in',
  'azure_auth',
  'canary',
];

export const RESPONSEDATE_IN_MILLIS = 'responseDateInMillis';
export const CURRENTDATE_IN_MILLIS = 'currentDateInMillis';
export const AZURE_B2C_REDIRECT_URI_PROD_B2C = 'https://beesgrow.bees-platform.com/';
export const BRF_PREFIX = 'BRF';

export const LINK_HOME = `/tlp/home`;
export const LINK_CALL = `/tlp/call`;
export const LINK_CALL_CUSTOMER = '/tlp/call/customer';
export const LINK_CALL_GAP = '/tlp/call/gap';
export const LINK_CALL_ORDERS = '/tlp/call/orders';
export const LINK_CALL_B2BTRAINER = '/tlp/call/b2btrainer';

export const ANALYTICS_ROUTE_NAMES = new Map<string, string>([
  [LINK_HOME, 'Agent KPIs'],
  [LINK_CALL, 'Call - GAP and Status'],
  [LINK_CALL_GAP, 'Call - GAP and Status'],
  [LINK_CALL_B2BTRAINER, 'Call - B2B Trainer'],
  [LINK_CALL_ORDERS, 'Call - Orders'],
  [LINK_CALL_CUSTOMER, 'Customer'],
]);

export const LOCAL_STORAGE_PORTAL_CONFIG = 'portal-config';
export const WRAPPER_PROJECT_NAME_INSTANCE = 'admin-portal-tlp-beta-mfe';
