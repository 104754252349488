/* istanbul ignore file */

import { ReactNode } from 'react';
import { createIntl, createIntlCache, defineMessages, IntlShape } from 'react-intl';
import enUS from './languages/en-US';
import es419 from './languages/es-419';
import ptBR from './languages/pt-BR';

export const DEFAULT_LANGUAGE = 'en-US';

type IIntlProvider = {
  children: ReactNode;
};

interface FlatMessageMap {
  [key: symbol | string]: any;
}

export type MessageMap = {
  [key: string]: { [key: string]: string | MessageMap } | string;
};

export const MESSAGES: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};

const flattenMessages = (nestedMessages: MessageMap): FlatMessageMap =>
  Object.entries(nestedMessages).reduce(
    (messages, [messageKey, message]) =>
      Object.assign(
        messages,
        Object.fromEntries(
          Object.entries(message).map(([key, value]) => [`${messageKey}.${key}`, value])
        )
      ),
    {}
  );

const validateSelectedLanguage = (language: string) => {
  if (!MESSAGES[language]) {
    return DEFAULT_LANGUAGE;
  }
  return language;
};

const defaultMessageMap = flattenMessages(MESSAGES[DEFAULT_LANGUAGE]);

const fillFlattenedMessaged = (messageMap: FlatMessageMap): FlatMessageMap => ({
  ...defaultMessageMap,
  ...messageMap,
});

const intlProvider = (locale: string): IntlShape => {
  const cache = createIntlCache();
  const language = validateSelectedLanguage(locale);
  return createIntl(
    {
      locale: language,
      messages: defineMessages(fillFlattenedMessaged(flattenMessages(MESSAGES[language]))),
    },
    cache
  );
};

export default intlProvider;
