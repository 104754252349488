import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Outlet } from '../components/Outlet/Outlet';

export type RoutesType = {
  routes: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export function useMakeRoutes(): RoutesType {
  const routes = useRoutes([
    {
      path: '*',
      element: <Outlet />,
    },
  ]);

  return { routes };
}

export default useMakeRoutes;
