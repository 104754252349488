/* istanbul ignore file */
import { useAnalyticsService, useUserMetadata } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { EnvConfig } from '../../components';
import { updateIntlProvider } from '../../i18n/formatters';

type AppProps = EnvConfig & { TLP_SEGMENT_KEY: string };

const useAppConfig = (props: AppProps) => {
  const analyticsService = useAnalyticsService();
  const { data } = useUserMetadata();

  useEffect(() => {
    updateIntlProvider(data?.profile?.preferredLanguage || 'en-US');
  }, [data?.profile?.preferredLanguage]);

  useEffect(() => {
    analyticsService.load(props.TLP_SEGMENT_KEY);
  }, [props.TLP_SEGMENT_KEY]);
};

export default useAppConfig;
