import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Tradução',
  },
  Error500: {
    title: 'Opa! Algo deu errado',
  },
  Error403: {
    title: 'Hmm... Acesso negado!',
    description: 'Parece que você não tem permissão para acessar esta página.',
    button: 'Início',
  },
  Error404: {
    title: 'Mmm... Parece que esta página se foi',
    description: 'Parece que esta página não foi encontrada ou não existe.',
    button: 'Início',
  },
  Error503: {
    title: 'Hmm... Serviço Indisponível!',
    description:
      'Nossos servidores estão indisponíveis temporariamente. Você pode tentar novamente em alguns minutos e a situação já deverá estar normalizada.',
    button: 'Tente Novamente',
    infoText: 'Se o problema persistir, você pode <a>relatá-lo.</a>',
  },
  AvatarMenu: {
    signOut: 'Sair',
    account: 'Conta',
    getHelp: 'Obter Ajuda',
    privacyPolicy: 'Política de Privacidade',
    termsOfService: 'Termos de Serviço',
    userProfile: 'Perfil do Usuário',
  },
  Dashboard: {
    tileButtonAlt: 'Exibir em Grade',
    listButtonAlt: 'Exibir em Lista',
    pageTitle: 'Página Inicial',
    errorPage: 'Nada para ver aqui',
  },
  Notification: {
    passwordChanged: 'Senha alterada com sucesso!',
  },
  SideBar: {
    HOME: 'Home',
    CALL: 'Chamar',
  },
};

export default ptBR;
