import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMakeRoutes } from './hooks';
import Styled from './Routes.styles';

const Routes = ({ isLoaded }) => {
  const { routes } = useMakeRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded) {
      navigate('/tlp/home');
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return (
      <Styled.Routes>
        <CircularProgress disableShrink />
      </Styled.Routes>
    );
  }

  return routes;
};

export default Routes;
