import Drawer from '@material-ui/core/Drawer';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import CallIcon from '../../assets/CallIcon';
import HomeIcon from '../../assets/HomeIcon';
import { formatMessage } from '../../i18n/formatters';
import useSidebarStyle from './Sidebar.styles';
import ListOptions from './components/ListOptions/ListOptions';
export interface SidebarItem {
  id: string;
  text: string;
  path: string;
  icon: React.ElementType | null;
}

const EVENTS = {
  autodialer: "autodialer",
};


export const Sidebar = (): JSX.Element => {
  const { drawer, root, drawerClose, sideBarHeader } = useSidebarStyle();
  const [autodialerEnable, setAutodialerEnable] = useState<boolean>(false);
  const callItem: SidebarItem = {
    id: 'Call',
    text: `${formatMessage({ id: 'SideBar.CALL' })}`,
    path: '/tlp/call',
    icon: CallIcon,
  };  
  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([
    {
      id: 'Home',
      text: `${formatMessage({ id: 'SideBar.HOME' })}`,
      path: '/tlp/home',
      icon: HomeIcon,
    },    
  ]);

  useEffect(()=>{
    window.addEventListener(EVENTS.autodialer, (e: CustomEvent) =>{
      setAutodialerEnable(e.detail);
    });
  },[]);

  useEffect(()=>{
    if(autodialerEnable){
      setSidebarItems(sidebarItems.filter(item => item.id !== callItem.id));
      return;
    }

    setSidebarItems([...sidebarItems, callItem]);  
  },[autodialerEnable]);

  return (
    <Drawer
      data-testid="sidebar-drawer"
      variant={'permanent'}
      open
      className={cx('beesGrow', drawer)}
      classes={{
        paper: cx(root, drawerClose, sideBarHeader),
      }}
    >
      {sidebarItems.length > 0 && <ListOptions menuOptions={sidebarItems} />}
    </Drawer>
  );
};

export default Sidebar;
