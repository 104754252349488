import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 260;
const numberOfSpacing = 3;
const Sidebar = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#212121',
  },
  container: {
    display: 'flex',
    zIndex: 1,
  },
  menuText: {
    color: '#9e9e9e',
    fontSize: 11,
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 0,
  },
  menuSelectedText: {
    color: '#ff0',
    fontSize: 11,
    fontWeight: 'bold',
    fontFamily: 'Work Sans',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(numberOfSpacing) + 1,
    [theme.breakpoints.up('xs')]: {
      width: '4rem',
    },
  },
  languageWrapper: {
    paddingTop: 8,
  },
  listItemSelected: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#fff',
    },
  },
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  block: {
    display: 'flex',
    alignItems: 'space-around',
    flexDirection: 'column',
    height: '100%',
    minWidth: '3.5rem',
  },
  footer: {
    padding: '1rem',
    marginTop: '2rem',
  },
  footerParagraph: {
    fontSize: '0.9rem',
  },
  sideBarHeader: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '5rem',
      zIndex: 1,
      height: '100% !important',
    },
  },
}));

export default Sidebar;
