import { createTheme } from '@material-ui/core/styles';
import overrides from './Overrides';
import palette from './Palette';
import typography from './Typography';

const theme = createTheme({
  palette,
  typography,
  overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1367,
      xl: 1920,
    },
  },
});

export default theme;
