import { useAppBarConfigurationService } from 'admin-portal-shared-services';
import { useStore } from 'effector-react';

export const useSidebar = () => {
  const appBarStore = useAppBarConfigurationService().getStore();
  const store = useStore(appBarStore);

  const isSidebarAvailable = store.hasSidebar;

  return {
    ...store,
    showSidebar: isSidebarAvailable,
  };
};
