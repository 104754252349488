/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import optimizelySDK from '@optimizely/optimizely-sdk';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCanaryConfig = ({ optKey }: { optKey: string }): any => {
  const [cookies, setCookie] = useCookies();
  const [optimizelyKey] = useState(optKey);
  const [isLoaded, setIsLoaded] = useState(false);

  const setIsCanary = useCallback(
    async (emailB2C: string, countryB2C: string) => {
      const optimizely = optimizelySDK.createInstance({
        datafileOptions: {
          autoUpdate: true,
          updateInterval: 60000,
        },
        logLevel: 4,
        sdkKey: optimizelyKey,
      });

      await optimizely.onReady();

      const attributes = {
        email: emailB2C,
        country: countryB2C,
      };

      const AUDIENCE = optimizely.isFeatureEnabled(
        'tlp-email-audience',
        `${emailB2C}_${countryB2C}`,
        attributes
      );

      if (AUDIENCE) {
        if (!cookies.version || cookies.version !== 'canary') {
          setCookie('version', 'canary', { path: '/' });
        }
        localStorage.setItem('version', 'canary');
      } else {
        if (!cookies.version || cookies.version !== 'release') {
          setCookie('version', 'release', { path: '/' });
        }
        localStorage.setItem('version', 'release');
      }

      setIsLoaded(true);
    },
    [cookies.version, optimizelyKey, setCookie]
  );
  // eslint-disable-next-line no-console
  console.log('version', cookies.version);
  return { setIsCanary, isLoaded };
};

export default useCanaryConfig;
