import { createFeatureToggleServiceV2, useLogService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import { axiosIntercept } from './config/axios/axiosInstance';
import './grow-portal-main-mfe.css';
import { setupLocalEnv } from './setupLocal';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './utils/constants';
import { getPortalConfig } from './utils/functions';

axiosIntercept();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!window.Cypress && process.env.STANDALONE && process.env.NODE_ENV === 'development') {
  setupLocalEnv();
}

const sdkKey: string = getPortalConfig().OPTIMIZELY_KEY;
createFeatureToggleServiceV2(WRAPPER_PROJECT_NAME_INSTANCE, sdkKey || '');

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    useLogService().error(error);

    return <></>;
  },
});

export const mount = [
  async () => {
    if (document.getElementById('grow-container')) {
      document.getElementById('grow-container').className = 'grow-portal-main-mfe-root';
    }
  },
  reactLifecycles.mount,
];

export const { bootstrap, unmount } = reactLifecycles;
