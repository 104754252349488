/* eslint-disable camelcase */
import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosError } from 'axios';
import { v4 as guid } from 'uuid';
import {
  getCanaryRelease,
  getCountryByToken,
  getTokenLocalStorage,
  isTokenExpired,
} from '../../utils/functions';

const axiosInstance = axios.create();

const axiosIntercept = (): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getTokenLocalStorage();
      const authenticationService = useAuthenticationService();
      const expiration = authenticationService.getJWTExpiration();

      config.headers.requestTraceId = guid();
      config.headers.canary = getCanaryRelease();
      const countryUser: string = getCountryByToken();

      if (!countryUser || isTokenExpired(parseInt(expiration as string, 10))) {
        throw new Error('Invalid token!');
      }

      config.headers.Accept = 'application/json';
      config.headers.Authorization = token;
      config.headers.country = countryUser;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
};

export { axiosIntercept };
export default axiosInstance;
