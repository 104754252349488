declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand?: Palette['primary'];
  }
  interface PaletteOptions {
    brand?: PaletteOptions['primary'];
  }
}

export default {
  common: { white: '#FFFFFF', black: '#000000' },
  primary: { main: '#212121', contrastText: '#FFFFFF', light: '#1af07e' },
  secondary: { light: '#ffffff', main: '#212121', contrastText: '#ff0' },
  error: { main: '#c9201d', light: '#fbe9e9' },
  success: { main: '#d1f7f6' },
  warning: { main: '#ffebd0' },
  grey: {
    50: '#ffffff',
    100: '#eaeaea',
    200: '#9e9e9e',
    300: '#525252',
    400: '#212121',
    A100: '#dfdede',
  },
  text: {
    primary: '#212121',
    secondary: '#707372',
  },
  background: {
    paper: '#FFFFFF',
    default: '#fafafa',
  },
  info: {
    contrastText: '#705ea7',
    main: '#007a78',
    dark: '#9f5c00',
  },
};
