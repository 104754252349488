/* istanbul ignore file */
import { useAuthenticationService, useEnvService } from 'admin-portal-shared-services';
import { add as addToDate } from 'date-fns';
import {
  AZURE_B2C_REDIRECT_URI_PROD_B2C,
  CURRENTDATE_IN_MILLIS,
  LOCAL_STORAGE_PORTAL_CONFIG,
  RESPONSEDATE_IN_MILLIS,
  UPDATE_LOCAL_STORAGE_LOGOUT,
} from './constants';

export const getCountryByToken = (): string => {
  return useAuthenticationService().getCountryB2C();
};

export const getCanaryRelease = (): string | null => {
  return localStorage.getItem('canary');
};

export function isTokenExpired(exp: number): boolean {
  return Date.now() > exp * 1000;
}

export const isCanary = (): boolean | false => {
  return localStorage.getItem('canary') === 'simple-canary';
};

export const getResponseDate = () => {
  const currentDateInMillis = localStorage.getItem(CURRENTDATE_IN_MILLIS);
  const responseDateInMillis = localStorage.getItem(RESPONSEDATE_IN_MILLIS);
  const date = new Date();
  if (
    !currentDateInMillis ||
    !responseDateInMillis ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(<any>currentDateInMillis) ||
    // eslint-disable-next-line no-restricted-globals
    isNaN(<any>responseDateInMillis)
  )
    return date;

  const currentMillis = Number(Date.now());
  const millis = Number(currentDateInMillis);
  const interval = currentMillis - millis;
  const responseDate = new Date(responseDateInMillis);
  return addToDate(responseDate, { seconds: interval / 1000 });
};

export const removeItemsLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const removeListLocalStorage = (keys: string[]): void => {
  if (keys.length > 0) keys.forEach((key) => removeItemsLocalStorage(key));
};

const AUTH_HEADER_KEY = 'authHeader';
export const getTokenLocalStorage = (): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const portalConfig: { [key: string]: any } = useEnvService().getEnvConfig();
  const token = useAuthenticationService().getAuthHeader();

  if (!token || isEmpty(token)) {
    const tokenAux = localStorage.getItem(AUTH_HEADER_KEY);

    if (tokenAux) {
      useAuthenticationService().setAuthHeader(tokenAux);
      return tokenAux;
    }

    const endPointLogout = urlLogout(portalConfig.ENV);
    removeListLocalStorage(UPDATE_LOCAL_STORAGE_LOGOUT);
    window.location.href = endPointLogout;
    throw new Error('Invalid getTokenLocalStorage token!');
  }

  return token;
};

export const urlLogout = (ENV: string): string => {
  const urlOrigin = window.origin;

  if (
    process.env.NODE_ENV === 'development' ||
    ENV === 'dev' ||
    urlOrigin.indexOf('cx-tlp-dev') > -1
  ) {
    return createUrlLogout('dev', 'dev');
  }

  if (ENV === 'uat' || urlOrigin.indexOf('cx-tlp-uat') > -1) {
    return createUrlLogout('uat1', 'uat');
  }

  return `https://b2biamgbusprod.b2clogin.com/b2biamgbusprod.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=${AZURE_B2C_REDIRECT_URI_PROD_B2C}`;
};

function createUrlLogout(prefix: string, sufix: string): string {
  return `https://b2biamgbus${prefix}.b2clogin.com/b2biamgbus${prefix}.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_BEES_SIGNIN_ONCUSTOMER&redirect_uri=https://cx-tlp-${sufix}.bees-platform.dev/tlp/home`;
}

export const isEmpty = (value) => {
  return (
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const returnValueArray = (values: Array<string> | null, position: number) => {
  if (values == null) {
    return null;
  }

  return values[position] || null;
};

export const getScreenResolution = () =>
  `${window.screen.width * window.devicePixelRatio} x ${
    window.screen.height * window.devicePixelRatio
  }`;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueOrNull = (value: any): any => value || null;

export const getPortalConfig = () => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);
  if (!portalLocalStorage) return undefined;
  return JSON.parse(portalLocalStorage);
};
