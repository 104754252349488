import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export const SidebarContext = createContext({
  isSidebarOpen: true,
  toggleSidebar: null,
  setIsSideBarOpen: null,
});

export const useSidebarContext = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSideBarOpen] = useState(true);
  const toggleSidebar = useCallback(() => setIsSideBarOpen((prev) => !prev), [setIsSideBarOpen]);

  const values = useMemo(() => {
    return {
      isSidebarOpen,
      toggleSidebar,
      setIsSideBarOpen,
    };
  }, [isSidebarOpen, toggleSidebar, setIsSideBarOpen]);

  return <SidebarContext.Provider value={values}>{children}</SidebarContext.Provider>;
};
