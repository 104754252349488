import { forwardRef } from 'react';
import { Svg } from './Icon.styles';
import { IconProps } from './IconProps.types';

const HomeIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'large', fill, ...rest }, forwardedRef): JSX.Element => (
    <Svg viewBox="-2 -5 24 24" ref={forwardedRef} size={size} {...rest} data-testid="HomeIcon">
      <g fill="none" fillRule="evenodd">
        <path
          fill={fill}
          d="M11.598 3.226l2.2 1.78v-3.49c0-.224-.165-.406-.367-.406h-1.468c-.202 0-.366.182-.367.405l.002 1.711zM2.186 17.986h3.272v-6.079c-.001-.445.323-.808.726-.811h3.268c.403.003.727.366.726.811v6.079h3.635c.603-.007 1.089-.55 1.089-1.216V8.66L8.453 3.277c-.13-.104-.29-.156-.45-.146-.165.001-.326.058-.461.163L1.097 8.66v8.11c0 .666.485 1.21 1.089 1.216z"
          transform="translate(-18 -109) translate(0 56) translate(11 53) translate(8 .003)"
        />
        <path
          stroke={fill}
          strokeLinecap="round"
          d="M0 7.192L7.455.704c.34-.203.75-.203 1.091 0L16 6.783"
          transform="translate(-18 -109) translate(0 56) translate(11 53) translate(8 .003)"
        />
      </g>
    </Svg>
  )
);

export default HomeIcon;
