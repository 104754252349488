import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import cx from 'classnames';
import { SidebarItem } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { sidebarInteraction } from '../../../../config/typewriter';
import { ANALYTICS_ROUTE_NAMES } from '../../../../utils/constants';
import { getValueOrNull } from '../../../../utils/functions';
import useSidebarStyle from '../../Sidebar.styles';
import useListOptionsStyle from './ListOptions.styles';

export interface ListOptionsProps {
  menuOptions: Array<SidebarItem>;
}

const ListOptions = ({ menuOptions }: ListOptionsProps): JSX.Element => {
  const { listItemSelected, menuSelectedText, menuText } = useSidebarStyle();
  const { menuIcon, container } = useListOptionsStyle();
  const navigate = useNavigate();
  const location = useLocation();

  /* istanbul ignore next */
  const changePage = (path: string, label: string, id: string) => {
    if (!location.pathname.includes(path)) {
      sidebarInteraction({
        button_label: label,
        button_name: `navigation-item-${id === 'Call' ? 'call' : 'home'}`,
        button_selection: '',
        screen_name: getValueOrNull(
          ANALYTICS_ROUTE_NAMES.get(`${location.pathname}${location.search}`)
        ),
      });
      navigate(path);
    }
  };

  return (
    <List>
      {menuOptions.map((menu) => {
        const Icon = menu.icon;
        return (
          <ListItem
            selected={location.pathname === menu.path}
            button
            disableGutters
            key={menu.id}
            onClick={() => {
              changePage(menu.path, menu.text, menu.id);
            }}
            classes={{
              selected: listItemSelected,
            }}
            data-testid="sidebar-list-item"
          >
            <Grid container className={container} justifyContent="center">
              <ListItemIcon className={menuIcon} data-testid="sidebar-icon">
                {Icon && <Icon fill={location.pathname.includes(menu.path) ? '#FF0' : '#9E9E9E'} />}
              </ListItemIcon>

              <ListItemText
                classes={{
                  primary: cx(menuText, location.pathname.includes(menu.path) && menuSelectedText),
                }}
                primary={menu.text}
              />
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListOptions;
