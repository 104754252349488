/* istanbul ignore file */
/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );
  console.warn(msg);
};
let onViolation = defaultValidationErrorHandler;
let analytics = () => {
  return window.analytics;
};
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}
/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message, schema) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));
  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'javascript',
        version: '7.1.0',
      },
    },
  };
}
/**
 * @typedef I360ViewTabInteraction
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef AgentKpIsUxButtonClicked
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef B2BLoginInformationCopied
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 */
/**
 * @typedef B2BTrainerFailed
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef B2BTrainerStarted
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef CtiDisconnected
 * @property {string | null} action - Action choice. Samples: retry, continue
 * @property {number | null} call_duration - The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} phone_rank - Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {any[] | null} phone_source - Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]

 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef MissionsItem
 * @property {string | null} comments - Free text comment about the mission. Sample: POC had technical issues
 * @property {boolean | null} effectiveness - Effectiveness of the mission. It's a boolean
 * @property {string | null} priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} reason - Reason of the mission (list values from the dropdown: LOW_DEMAND, NO_CREDIT_AVAILABLE, FORGOT)
 * @property {string | null} tag - Tag of the mission. Sample: #PromoteMarketplace

 * @property {string | null} type - Type of the mission. Samples: Marketplace Activation, Rewards Redeem, Gap

 * @property {string | null} typification - Typification of the mission (list values from the dropdown: Uplifted after call, Uplifted, No Success On Uplift)
 */
/**
 * @typedef PhonesItem
 * @property {string | null} phone_number - Phone number. Sample: 1136721420
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {any[] | null} phone_source - Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]
 */
/**
 * @typedef CallEndFailed
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} DDC_name - Name of the Direct Distribution Center
 * @property {number | null} accumulative_discount - Amount of accumulative discount available for the POC. Sample: 1,350.00
 * @property {number | null} analysis_time - Page Gap & Status is loaded until the time he clicks on "start call" button. Samples: 15, 20
 * @property {number | null} call_attempts - Number of calls already attempted to that POC at the current day. Sample: 3
 * @property {number | null} call_duration - The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {any[] | null} campaign_tags_assigned - Tag(s) of the campaign(s) assigned to the POC. Samples: #b2o-pitstop, #summer-favorites
 * @property {any[] | null} campaign_types_assigned - Tag(s) of the campaign(s) assigned to the POC. Samples: B2O, Focus SKU
 * @property {any[] | null} campaigns_assigned - Name of the campaigns assigned to the POC. Sample: Summer Favorites

 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {boolean | null} is_b2b_trained - if the agent trained the POC using B2B Trainer. It's a boolean
 * @property {boolean | null} is_cti_connected - If the CTI was connected during the call. True if yes False if not
 * @property {boolean | null} is_pasted - if the number dialed was pasted into the "dial a new number" field. It's a boolean
 * @property {boolean | null} is_promotion_offered - If the agent clicked on any expand button in the promotion section. It's a boolean
 * @property {boolean | null} is_searched_by_poc - If the POC was gotten by the Search by POC feature or standard call list. It's a boolean
 * @property {boolean} is_to_recommend_used - if the agent performed a search in the To Recommend section. It's a boolean
 * @property {boolean | null} is_typed - if the agent has dialed the POC phone number manually. It's a boolean
 * @property {MissionsItem[] | null} missions - the array of missions objects
 * @property {number} overdue - POC debit Sample: 3,284.50
 * @property {PhonesItem[] | null} phones - The array of all phones from the POC
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {number | null} points_lost - Reward points the POC lost by using the order taking. Sample: 347
 * @property {any[] | null} redemption_combos_available - comboId of the items available for redemption to that POC
 * @property {number | null} reward_point_balance - current balance of reward points for the POC. Sample: 9,600
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {number | null} service_duration - The duration of the service in seconds from the moment a new POC is got from the list. Samples: 30, 240
 * @property {any[] | null} tags - Tags associated with the POC. Samples: #LoginFailureNoCheckout7Days, #DiaB
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 * @property {string | null} vendor_id - Vendor ID. Sample: BRF
 */
/**
 * @typedef MissionsItem1
 * @property {string | null} comments - Free text comment about the mission. Sample: POC had technical issues
 * @property {boolean | null} effectiveness - Effectiveness of the mission. It's a boolean
 * @property {string | null} priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} reason - Reason of the mission (list values from the dropdown: LOW_DEMAND, NO_CREDIT_AVAILABLE, FORGOT)
 * @property {string | null} tag - Tag of the mission. Sample: #PromoteMarketplace

 * @property {string | null} type - Type of the mission. Samples: Marketplace Activation, Rewards Redeem, Gap

 * @property {string | null} typification - Typification of the mission (list values from the dropdown: Uplifted after call, Uplifted, No Success On Uplift)
 */
/**
 * @typedef PhonesItem1
 * @property {string | null} phone_number - Phone number. Sample: 1136721420
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {any[] | null} phone_source - Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]
 */
/**
 * @typedef CallEnded
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} DDC_name - Name of the Direct Distribution Center
 * @property {number | null} accumulative_discount - Amount of accumulative discount available for the POC. Sample: 1,350.00
 * @property {number | null} analysis_time - Page Gap & Status is loaded until the time he clicks on "start call" button. Samples: 15, 20
 * @property {number | null} call_attempts - Number of calls already attempted to that POC at the current day. Sample: 3
 * @property {number | null} call_duration - The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {any[] | null} campaign_tags_assigned - Tag(s) of the campaign(s) assigned to the POC. Samples: #b2o-pitstop, #summer-favorites
 * @property {any[] | null} campaign_types_assigned - Tag(s) of the campaign(s) assigned to the POC. Samples: B2O, Focus SKU
 * @property {any[] | null} campaigns_assigned - Name of the campaigns assigned to the POC. Sample: Summer Favorites

 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} credit_available - Amount of credit available to that POC at the start of the service. Samples: 1233.99
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {boolean | null} is_b2b_trained - if the agent trained the POC using B2B Trainer. It's a boolean
 * @property {boolean | null} is_cti_connected - If the CTI was connected during the call. True if yes False if not
 * @property {boolean | null} is_pasted - if the number dialed was pasted into the "dial a new number" field. It's a boolean
 * @property {boolean | null} is_promotion_offered - If the agent clicked on any expand button in the promotion section. It's a boolean
 * @property {boolean | null} is_resumed - If that service was paused and resumed. It's a boolean
 * @property {boolean | null} is_searched_by_poc - If the POC was gotten by the Search by POC feature or standard call list. It's a boolean
 * @property {boolean} is_to_recommend_used - if the agent performed a search in the To Recommend section. It's a boolean
 * @property {boolean | null} is_typed - if the agent has dialed the POC phone number manually. It's a boolean
 * @property {MissionsItem1[] | null} missions - the array of missions objects
 * @property {string | null} next_action - Next action choice after end call. Samples: call next client, go to Home screen
 * @property {number} overdue - POC debit Sample: 3,284.50
 * @property {PhonesItem1[] | null} phones - The array of all phones from the POC
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {number | null} points_lost - Reward points the POC lost by using the order taking. Sample: 347
 * @property {string | null} reason - Selected option as the reason. Samples: BEES issues
 * @property {string | null} reason_description - Free text comment on the reason field. Samples: Tive um problema com o app
 * @property {any[] | null} redemption_combos_available - comboId of the items available for redemption to that POC
 * @property {string | null} retry_call_time - When typification is "call again later" time frame for the new call trial. Samples: 30 min later
 * @property {number | null} reward_point_balance - current balance of reward points for the POC. Sample: 9,600
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {number | null} service_duration - The duration of the service in seconds from the moment a new POC is got from the list. Samples: 30, 240
 * @property {any[] | null} tags - Tags associated with the POC. Samples: #LoginFailureNoCheckout7Days, #DiaB
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 * @property {string | null} typification - Typification in the given context. Samples: NoAnswer, UpliftOnCall
 * @property {string | null} typification_description - Typification free text in the given context. Samples: Não tive resposta
 * @property {string | null} vendor_id - Vendor ID. Sample: BRF
 */
/**
 * @typedef CallFailed
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {boolean | null} is_pasted - if the number dialed was pasted into the "dial a new number" field. It's a boolean
 * @property {boolean | null} is_resumed - If that service was paused and resumed. It's a boolean
 * @property {boolean | null} is_typed - if the agent has dialed the POC phone number manually. It's a boolean
 * @property {number | null} phone_rank - Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} poc_potential - the potential the POC has to be uplifted. Sample: 5
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 */
/**
 * @typedef CallGapReasonRegistered
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 */
/**
 * @typedef CallGetNextPoc
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 * @property {string | null} vendor_id - Vendor ID. Sample: BRF
 */
/**
 * @typedef CallGetNextPocFailed
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 */
/**
 * @typedef CallReconnect
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 */
/**
 * @typedef CallStarted
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {boolean | null} is_cti_connected - If the CTI was connected during the call. True if yes False if not
 * @property {boolean | null} is_pasted - if the number dialed was pasted into the "dial a new number" field. It's a boolean
 * @property {boolean | null} is_typed - if the agent has dialed the POC phone number manually. It's a boolean
 * @property {number | null} phone_rank - Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} poc_potential - the potential the POC has to be uplifted. Sample: 5
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 * @property {string | null} vendor_id - Vendor ID. Sample: BRF
 */
/**
 * @typedef CallTabMenuInteraction
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 * @property {string | null} tab_name - Name of the tab in local language.  Samples: GAP & Status, Pedidos
 */
/**
 * @typedef CallUxButtonClicked
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {string | null} mission - Current mission. Sample: Marketplace Activation
 * @property {string | null} order_date - Date when the order was placed. Sample: 2022-05-06
 * @property {number | null} position - The position of the item in the list, starting by 1. Sample: 7
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef CallUxDropdownMenuInteraction
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {string | null} menu_label - Label of the dropdown menu. Samples: Product Label
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef CampaignViewed
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card

 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} campaign_name - Name of the current campaign being viewed. Sample: Summer Favourites

 * @property {string | null} campaign_tag - Tag of the campaign being viewed. Sample: #b2opitstop
 * @property {string | null} campaign_type - Type of the campaign. Samples: B2O, Rewards
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef CampaignsProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} combo_id - Combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef CampaignsProductQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef ComboAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} combo_id - Combo ID. Sample: DT01290007980
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef CustomerNoteUpdated
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef CustomerUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card

 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {string | null} typification - Typification in the given context. Samples: NoAnswer, UpliftOnCall
 */
/**
 * @typedef DeliveryDateChanged
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {boolean | null} is_alternative_date - If the delivery date chosen by the agent is an alternative. It's a boolean
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef EmptiesQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {number | null} empties_balance - Total balance of the empty. Sample: 7

 * @property {number | null} empties_required - Total empties required. Sample: 15
 * @property {string | null} empty_sku - SKU of the empty. Sample: 2000162
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 */
/**
 * @typedef ErrorMessageViewed
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} DDC_name - Name of the Direct Distribution Center
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} credit_available - Amount of credit available to that POC at the start of the service. Samples: 1233.99
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {boolean | null} is_all_products - If all of the products from the list were affected by SKU errors. True for all products or False if not all products. It's a boolean
 * @property {boolean | null} is_resumed - If that service was paused and resumed. It's a boolean
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 */
/**
 * @typedef ForgotPasswordCompleted
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 * @property {boolean | null} password_displayed - Was the user displaying their password in plaintext when they completed the action. It's a boolean
 */
/**
 * @typedef ForgotPasswordStarted
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 */
/**
 * @typedef ForgotPasswordSubmitted
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 */
/**
 * @typedef ForgotPasswordVerificationCompleted
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 */
/**
 * @typedef HomeHeaderInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} button_selection - Selected option. Samples: english, spanish
 */
/**
 * @typedef IdTabInteraction
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef LocalProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} combo_id - Combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef ProductsItem
 * @property {number | null} base_price - original price. Sample: 1179.84
 * @property {string | null} brand - name of brand. Sample: Brahma
 * @property {string | null} category - category of the item. Sample: Beer
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {string | null} image_url - image URL of the product. Sample: https://www.socioscerveceria.com.do/media/catalog/product/m/a/malta_morena_8_onz_pla_stica_-_fardos_24_unidades_en_6pack_malta_escolar_.png
 * @property {boolean | null} is_focus_sku - if the product was set as a Focus SKU. It's a boolean
 * @property {boolean | null} is_out_of_stock - if the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {boolean | null} is_recommended - if the product is recommended. It's a boolean
 * @property {string | null} name - name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Uni
 * @property {string | null} packaging - the product's packaging volume. Sample: 450.0ML
 * @property {number | null} position - the product's position in the list. Sample: 7
 * @property {number | null} price - price at which SKU is being sold (may differ from base_price for deals and promotions). Sample: 998.65
 * @property {number | null} priority - product priority, starting by the most prioritized as 1. Sample: 17
 * @property {string | null} promotion_type - the promotion type of the item. Sample: stepped discount
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 */
/**
 * @typedef LocalProductsLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} local_mission - title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {ProductsItem[] | null} products - The product array, containing all nested props
 * @property {string | null} sponsored_mission - title of the sponsored mission. Sample: New Michelob Ultra Longneck
 */
/**
 * @typedef LocalProductsQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef LoginFailed
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 * @property {boolean | null} password_displayed - Was the user displaying their password in plaintext when they completed the action. It's a boolean
 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 */
/**
 * @typedef LoginSuccessful
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 * @property {number | null} time_of_day - The hour of day the event occurred (drill down to hour only). Sample: 15, 09
 */
/**
 * @typedef MarketplaceProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {boolean | null} is_focus_sku - If the product was set as a Focus SKU. It's a boolean
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {boolean | null} is_recommended - If the product is recommended. It's a boolean

 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} priority - Product priority, starting by the most prioritized as 1. Sample: 17
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef MarketplaceProductQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef ProductsItem1
 * @property {boolean | null} is_focus_sku - if the product was set as a Focus SKU. It's a boolean
 * @property {boolean | null} is_out_of_stock - if the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {boolean | null} is_recommended - if the product is recommended. It's a boolean
 * @property {number | null} priority - product priority, starting by the most prioritized as 1. Sample: 17
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 */
/**
 * @typedef MarketplaceRecommendedProductsLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {ProductsItem1[]} products - The product array, containing all nested props
 */
/**
 * @typedef MissionsTabInteraction
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef NewOfferingsListLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} credit_available - Amount of credit available to that POC at the start of the service. Samples: 1233.99
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {number | null} list_quantity - Quantity of products listed for the query. Samples: 1, 7, 9
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 */
/**
 * @typedef NewPhoneAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} phone_number - Phone number. Sample: 1136721420

 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef OfferingsDropdownMenuInteraction
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef OfferingsProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} combo_id - Combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} promotions_section - Name of the promotions tab the user is in. Samples: promotions, combos
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef OfferingsProductQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef OfferingsSearchInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef OfferingsUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card

 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} promotions_section - Name of the promotions tab the user is in. Samples: promotions, combos
 */
/**
 * @typedef OrderCartTabInteraction
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef OrderCompleted
 * @property {number | null} accumulative_discount - Amount of accumulative discount available for the POC. Sample: 1,350.00
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} credit_available - Amount of credit available to that POC at the start of the service. Samples: 1233.99
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} details_free_text - Details described in the field. Samples: Shipping address may change, please double check
 * @property {boolean | null} had_technical_problem - if the agent placed the order for the POC due to technical problems on Bees. It's a boolean
 * @property {boolean | null} has_overprice - If the POC has overprice charge to be applied on products. True if yes, False if not
 * @property {boolean | null} is_alternative_date - If the delivery date chosen by the agent is an alternative. It's a boolean
 * @property {boolean | null} is_alternative_date_available - If the POC has the option of alternative delivery dates for delivery. It's a boolean
 * @property {string | null} order_id - The order ID in ERP. Samples: 105
 * @property {string | null} order_reason - free text on order confirmation with the reason why the agent took the order
 * @property {number} overdue - POC debit Sample: 3,284.50
 * @property {string | null} payment_method - Selected payment method. Samples: Cash, Bank slip
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {number | null} points_lost - Reward points the POC lost by using the order taking. Sample: 347
 * @property {number | null} revenue - Total cost before tax and shipping. Sample: 6,055.85
 * @property {number | null} shipping_cases - Number of cases to be delivered. Sample 937
 * @property {number | null} shipping_pallets - Number of pallets to be delivered. Sample: 30
 * @property {number | null} shipping_trucks - Number of necessary trucks to make the deliver. Sample: 2
 * @property {number | null} total - Total cost of the order after discounts and taxes. Sample: 6,035.70
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 */
/**
 * @typedef OrderTakingUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card

 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef PageViewed
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {string | null} path - Path of the screen. Samples: /call
 * @property {string | null} referrer - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {string | null} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} title - title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} url - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef PasswordDidNotReceiveOtp
 * @property {string | null} login_country - country of the user logging in. Sample: Brazil

 */
/**
 * @typedef PhoneClassified
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} phone_classification - Classification given to the number. Sample: Success

 * @property {string | null} phone_number - Phone number. Sample: 1136721420

 * @property {number | null} phone_rank - Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2
 * @property {any[] | null} phone_source - Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]

 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef PhoneCopied
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {number | null} phone_rank - Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2
 * @property {number | null} phone_score - The score in total points of the phone called. Sample: 20
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef PhoneEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} phone_number - Phone number. Sample: 1136721420

 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef PhoneRankChanged
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef ProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {number | null} original_quantity - Original quantity of the product when list is loaded
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {number | null} quantity_per_pallet - Quantity of the product that can be bundled per pallet (100)
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef ProductListLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {number | null} list_quantity - Quantity of products listed for the query. Samples: 1, 7, 9
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef ProductQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} [combo_id] - Combo ID. Sample: DT01290007980
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} edit_method - How was the good quantity edited in cart. Possible values: Arrow or Input or Automatic - Low Stock
 * @property {boolean | null} is_combo - if the item is a combo. True for yes, False for no
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {number | null} original_quantity - Original quantity of the product when list is loaded
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef ProductRemoved
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} [combo_id] - Combo ID. Sample: DT01290007980
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {boolean | null} is_combo - if the item is a combo. True for yes, False for no
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {number | null} original_quantity - Original quantity of the product when list is loaded
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} remove_method - How was the good removed from cart. Possible values: Remove Icon or Automatic - Out of Stock
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef ProductSearchInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef ProductSuggestedOrderLoaded
 * @property {string | null} DDC - The DDC (Direct Distribution Center) code. Samples: 40, 5
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} cart_id - The shopping cart's unique identifier
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {number | null} credit_available - Amount of credit available to that POC at the start of the service. Samples: 1233.99
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {boolean | null} is_resumed - If that service was paused and resumed. It's a boolean
 * @property {number | null} list_quantity - Quantity of products listed for the query. Samples: 1, 7, 9
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} poc_potential - the potential the POC has to be uplifted. Sample: 5
 * @property {number | null} poc_recurrence - How many times that POC was listed in TLP. Samples: 1, 5, 9
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {number | null} total_gap - Total gap to cover. It's a NUMBER. Samples: 15,264.60
 * @property {number | null} total_sku_bought - Total SKUs bought. Sample: 30
 * @property {number | null} total_sku_gap - Total SKU gap. Sample: 70
 * @property {number | null} total_sku_suggested - Total SKUs suggested. Sample: 100

 * @property {number | null} total_vol_gap - Total volume gap to cover. It's a NUMBER. Samples: 4,369.70
 */
/**
 * @typedef ProductValidationMessageViewed
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} message - Text displayed in the UI. Sample: item not available
 * @property {string | null} message_subtype - Type of the message. Sample: inventory
 * @property {string | null} message_type - Type of the message. Samples: warning, error

 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef PromotionsListLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {number | null} list_quantity_combo - Quantity of combo products listed for the query. Sample: 10
 * @property {number | null} list_quantity_promo - quantity of promotion products listed for the query. Sample: 05
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef PromotionsSearchInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 * @property {string | null} search_go_method - Method by which the user concluded the search. Samples: enter, go button
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef PromotionsTabInteraction
 * @property {string | null} promotions_section - Name of the promotions tab the user is in. Samples: promotions, combos
 * @property {string | null} tab_label - Tab label in English. Samples: Gap & Status, Orders
 */
/**
 * @typedef PromotionsUxButtonClicked
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} promotions_section - Name of the promotions tab the user is in. Samples: promotions, combos
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 */
/**
 * @typedef RetryButtonClicked
 * @property {string | null} error_message - Text displayed that describes the error Samples: Failed to log in, Please verify your connection
 * @property {string | null} error_type - Type of error. Samples: wrong password, no connection
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Screen Section. Samples: Get Reason
 */
/**
 * @typedef SearchByPocInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card

 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef SidebarInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} button_selection - Selected option. Samples: english, spanish
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 */
/**
 * @typedef SponsoredProductAdded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} combo_id - Combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - If the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {string | null} name - Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {number | null} quantity - Total quantity of the product added to cart. Sample: 3
 * @property {string | null} sku - Product sku
 */
/**
 * @typedef SponsoredProductQuantityEdited
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 */
/**
 * @typedef ProductsItem2
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980

 * @property {boolean | null} is_out_of_stock - if the product is out of stock. It's a boolean
 * @property {boolean | null} is_promotion - If the product is a promotion/deal. It's a boolean
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 */
/**
 * @typedef SponsoredProductsLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {ProductsItem2[]} products - The product array, containing all nested props
 * @property {string | null} sponsored_mission - title of the sponsored mission. Sample: New Michelob Ultra Longneck
 */
/**
 * @typedef ToRecommendListLoaded
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {string | null} call_trigger - What triggered the call. Samples: no order, out of parameter
 * @property {string | null} country - Country of the POC. Samples: Brazil
 * @property {string | null} currency - ISO currency - Currency associated with product. Samples: BRL, DOP
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core
 * @property {number | null} list_quantity - Quantity of products listed for the query. Samples: 1, 7, 9
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} poc_segment - The segment of the POC. Samples: Food Led
 * @property {string | null} poc_subsegment - The subsegment of the POC. Samples: Cafeteria
 * @property {string | null} search_query - The text typed in the product search field. Samples: Brahma Pilsen
 */
/**
 * @typedef TopBarInteraction
 * @property {string | null} button_label - Button label in English. Samples: Reload
 * @property {string | null} button_name - Button name in local language. Samples: Recarregar
 * @property {string | null} button_selection - Selected option. Samples: english, spanish
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 */
/**
 * @typedef WarningMessageViewed
 * @property {string | null} call_id - ID of the call. Samples: 12123
 * @property {any[] | null} low_stock_skus - SKUs that has a lower stock than the quantity selected. Sample: ["0000371",  "0002006"]
 * @property {any[] | null} out_of_stock_skus - SKUs that are out of stock Sample: ["0000371",  "0002006"]
 * @property {string | null} poc_id - ID of the POC. Samples: 446109
 * @property {string | null} screen_name - Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} warning_message - Text displayed in the UI that describes the warning. Sample: There are products out of stock inside the cart.
 */
/**
 * User toggles between the Sales Structure and POC Information tabs inside 360º View
 *
 * @param {I360ViewTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function I360ViewTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User toggles between the Sales Structure and POC Information tabs inside 360º View',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: '360 View Tab Interaction',
    type: 'object',
  };
  const message = {
    event: '360 View Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('360 View Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the update button in the daily results section of the Agent KPIs page
 *
 * @param {AgentKpIsUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function agentKpIsUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the update button in the daily results section of the Agent KPIs page',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: ['button_name', 'button_label', 'screen_section', 'filter_option'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Agent KPIs UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Agent KPIs UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Agent KPIs UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User copies the login information in the B2B Trainer
 *
 * @param {B2BLoginInformationCopied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function b2BLoginInformationCopied(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User copies the login information in the B2B Trainer',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'button_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'B2B Login Information Copied',
    type: 'object',
  };
  const message = {
    event: 'B2B Login Information Copied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('B2B Login Information Copied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Start" or "use the web version" in the B2B Trainer but action returns an error
 *
 * @param {B2BTrainerFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function b2BTrainerFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Start" or "use the web version" in the B2B Trainer but action returns an error',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['error_message', 'error_type', 'call_id', 'country', 'poc_id', 'button_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'B2B Trainer Failed',
    type: 'object',
  };
  const message = {
    event: 'B2B Trainer Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('B2B Trainer Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Start" or "use the web version" in the B2B Trainer and the emulator is launched.
 *
 * @param {B2BTrainerStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function b2BTrainerStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Start" or "use the web version" in the B2B Trainer and the emulator is launched.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['country', 'poc_id', 'button_label', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'B2B Trainer Started',
    type: 'object',
  };
  const message = {
    event: 'B2B Trainer Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('B2B Trainer Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User takes action after the connection with CTI is lost and the modal is displayed
 *
 * @param {CtiDisconnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctiDisconnected(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User takes action after the connection with CTI is lost and the modal is displayed',
    properties: {
      context: {},
      properties: {
        properties: {
          action: {
            description: 'Action choice. Samples: retry, continue',
            type: ['string', 'null'],
          },
          call_duration: {
            description:
              'The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240',
            type: ['number', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          phone_rank: {
            description:
              'Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2',
            type: ['integer', 'null'],
          },
          phone_score: {
            description: 'The score in total points of the phone called. Sample: 20',
            type: ['integer', 'null'],
          },
          phone_source: {
            description:
              'Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]\n',
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: [
          'screen_section',
          'call_id',
          'poc_id',
          'phone_score',
          'call_duration',
          'action',
          'phone_source',
          'phone_rank',
          'country',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'CTI Disconnected',
    type: 'object',
  };
  const message = {
    event: 'CTI Disconnected',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('CTI Disconnected', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Call End Failed
 *
 * @param {CallEndFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callEndFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Call End Failed',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          DDC_name: {
            description: 'Name of the Direct Distribution Center',
            type: ['string', 'null'],
          },
          accumulative_discount: {
            description: 'Amount of accumulative discount available for the POC. Sample: 1,350.00',
            type: ['number', 'null'],
          },
          analysis_time: {
            description:
              'Page Gap & Status is loaded until the time he clicks on "start call" button. Samples: 15, 20',
            type: ['integer', 'null'],
          },
          call_attempts: {
            description:
              'Number of calls already attempted to that POC at the current day. Sample: 3',
            type: ['integer', 'null'],
          },
          call_duration: {
            description:
              'The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240',
            type: ['number', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          campaign_tags_assigned: {
            description:
              'Tag(s) of the campaign(s) assigned to the POC. Samples: #b2o-pitstop, #summer-favorites',
            type: ['array', 'null'],
          },
          campaign_types_assigned: {
            description: 'Tag(s) of the campaign(s) assigned to the POC. Samples: B2O, Focus SKU',
            type: ['array', 'null'],
          },
          campaigns_assigned: {
            description: 'Name of the campaigns assigned to the POC. Sample: Summer Favorites\n',
            type: ['array', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          is_b2b_trained: {
            description: "if the agent trained the POC using B2B Trainer. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_cti_connected: {
            description: 'If the CTI was connected during the call. True if yes False if not',
            type: ['boolean', 'null'],
          },
          is_pasted: {
            description:
              'if the number dialed was pasted into the "dial a new number" field. It\'s a boolean',
            type: ['boolean', 'null'],
          },
          is_promotion_offered: {
            description:
              "If the agent clicked on any expand button in the promotion section. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_searched_by_poc: {
            description:
              "If the POC was gotten by the Search by POC feature or standard call list. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_to_recommend_used: {
            description:
              "if the agent performed a search in the To Recommend section. It's a boolean",
            type: 'boolean',
          },
          is_typed: {
            description: "if the agent has dialed the POC phone number manually. It's a boolean",
            type: ['boolean', 'null'],
          },
          missions: {
            description: 'the array of missions objects',
            items: {
              description: 'A single mission assigned to the POC',
              properties: {
                comments: {
                  description:
                    'Free text comment about the mission. Sample: POC had technical issues',
                  type: ['string', 'null'],
                },
                effectiveness: {
                  description: "Effectiveness of the mission. It's a boolean",
                  type: ['boolean', 'null'],
                },
                priority: {
                  description: 'Prioritization of the mission. Samples: Primary, Secondary',
                  type: ['string', 'null'],
                },
                reason: {
                  description:
                    'Reason of the mission (list values from the dropdown: LOW_DEMAND, NO_CREDIT_AVAILABLE, FORGOT)',
                  type: ['string', 'null'],
                },
                tag: {
                  description: 'Tag of the mission. Sample: #PromoteMarketplace\n',
                  type: ['string', 'null'],
                },
                type: {
                  description:
                    'Type of the mission. Samples: Marketplace Activation, Rewards Redeem, Gap\n',
                  type: ['string', 'null'],
                },
                typification: {
                  description:
                    'Typification of the mission (list values from the dropdown: Uplifted after call, Uplifted, No Success On Uplift)',
                  type: ['string', 'null'],
                },
              },
              required: [
                'tag',
                'type',
                'effectiveness',
                'comments',
                'typification',
                'reason',
                'priority',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          overdue: {
            description: 'POC debit Sample: 3,284.50',
            type: 'number',
          },
          phones: {
            description: 'The array of all phones from the POC',
            items: {
              description: 'A single phone from the POC',
              properties: {
                phone_number: {
                  description: 'Phone number. Sample: 1136721420',
                  type: ['string', 'null'],
                },
                phone_score: {
                  description: 'The score in total points of the phone called. Sample: 20',
                  type: ['integer', 'null'],
                },
                phone_source: {
                  description:
                    'Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]',
                  type: ['array', 'null'],
                },
              },
              required: ['phone_number', 'phone_score', 'phone_source'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          points_lost: {
            description: 'Reward points the POC lost by using the order taking. Sample: 347',
            type: ['number', 'null'],
          },
          redemption_combos_available: {
            description: 'comboId of the items available for redemption to that POC',
            type: ['array', 'null'],
          },
          reward_point_balance: {
            description: 'current balance of reward points for the POC. Sample: 9,600',
            type: ['integer', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          service_duration: {
            description:
              'The duration of the service in seconds from the moment a new POC is got from the list. Samples: 30, 240',
            type: ['number', 'null'],
          },
          tags: {
            description:
              'Tags associated with the POC. Samples: #LoginFailureNoCheckout7Days, #DiaB',
            type: ['array', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
          vendor_id: {
            description: 'Vendor ID. Sample: BRF',
            id: '/properties/properties/properties/vendor_id',
            type: ['string', 'null'],
          },
        },
        required: [
          'total_vol_gap',
          'is_b2b_trained',
          'is_pasted',
          'campaigns_assigned',
          'redemption_combos_available',
          'is_to_recommend_used',
          'is_promotion_offered',
          'phones',
          'currency',
          'call_duration',
          'vendor_id',
          'analysis_time',
          'reward_point_balance',
          'is_typed',
          'missions',
          'is_searched_by_poc',
          'call_id',
          'service_duration',
          'accumulative_discount',
          'poc_subsegment',
          'poc_id',
          'country',
          'call_trigger',
          'call_attempts',
          'error_type',
          'campaign_tags_assigned',
          'campaign_types_assigned',
          'screen_name',
          'is_cti_connected',
          'DDC',
          'total_gap',
          'overdue',
          'tags',
          'poc_segment',
          'points_lost',
          'error_message',
          'DDC_name',
          'time_of_day',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call End Failed',
    type: 'object',
  };
  const message = {
    event: 'Call End Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call End Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Register" end call action with success
 *
 * @param {CallEnded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callEnded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "Register" end call action with success',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          DDC_name: {
            description: 'Name of the Direct Distribution Center',
            type: ['string', 'null'],
          },
          accumulative_discount: {
            description: 'Amount of accumulative discount available for the POC. Sample: 1,350.00',
            type: ['number', 'null'],
          },
          analysis_time: {
            description:
              'Page Gap & Status is loaded until the time he clicks on "start call" button. Samples: 15, 20',
            type: ['integer', 'null'],
          },
          call_attempts: {
            description:
              'Number of calls already attempted to that POC at the current day. Sample: 3',
            type: ['integer', 'null'],
          },
          call_duration: {
            description:
              'The duration of the call in seconds from the moment the user clicks on "start call". Samples: 30, 240',
            type: ['number', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          campaign_tags_assigned: {
            description:
              'Tag(s) of the campaign(s) assigned to the POC. Samples: #b2o-pitstop, #summer-favorites',
            type: ['array', 'null'],
          },
          campaign_types_assigned: {
            description: 'Tag(s) of the campaign(s) assigned to the POC. Samples: B2O, Focus SKU',
            type: ['array', 'null'],
          },
          campaigns_assigned: {
            description: 'Name of the campaigns assigned to the POC. Sample: Summer Favorites\n',
            type: ['array', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          credit_available: {
            description:
              'Amount of credit available to that POC at the start of the service. Samples: 1233.99',
            type: ['number', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          is_b2b_trained: {
            description: "if the agent trained the POC using B2B Trainer. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_cti_connected: {
            description: 'If the CTI was connected during the call. True if yes False if not',
            type: ['boolean', 'null'],
          },
          is_pasted: {
            description:
              'if the number dialed was pasted into the "dial a new number" field. It\'s a boolean',
            type: ['boolean', 'null'],
          },
          is_promotion_offered: {
            description:
              "If the agent clicked on any expand button in the promotion section. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_resumed: {
            description: "If that service was paused and resumed. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_searched_by_poc: {
            description:
              "If the POC was gotten by the Search by POC feature or standard call list. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_to_recommend_used: {
            description:
              "if the agent performed a search in the To Recommend section. It's a boolean",
            type: 'boolean',
          },
          is_typed: {
            description: "if the agent has dialed the POC phone number manually. It's a boolean",
            type: ['boolean', 'null'],
          },
          missions: {
            description: 'the array of missions objects',
            items: {
              description: 'A single mission assigned to the POC',
              properties: {
                comments: {
                  description:
                    'Free text comment about the mission. Sample: POC had technical issues',
                  type: ['string', 'null'],
                },
                effectiveness: {
                  description: "Effectiveness of the mission. It's a boolean",
                  type: ['boolean', 'null'],
                },
                priority: {
                  description: 'Prioritization of the mission. Samples: Primary, Secondary',
                  type: ['string', 'null'],
                },
                reason: {
                  description:
                    'Reason of the mission (list values from the dropdown: LOW_DEMAND, NO_CREDIT_AVAILABLE, FORGOT)',
                  type: ['string', 'null'],
                },
                tag: {
                  description: 'Tag of the mission. Sample: #PromoteMarketplace\n',
                  type: ['string', 'null'],
                },
                type: {
                  description:
                    'Type of the mission. Samples: Marketplace Activation, Rewards Redeem, Gap\n',
                  type: ['string', 'null'],
                },
                typification: {
                  description:
                    'Typification of the mission (list values from the dropdown: Uplifted after call, Uplifted, No Success On Uplift)',
                  type: ['string', 'null'],
                },
              },
              required: [
                'tag',
                'type',
                'effectiveness',
                'comments',
                'typification',
                'reason',
                'priority',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          next_action: {
            description:
              'Next action choice after end call. Samples: call next client, go to Home screen',
            type: ['string', 'null'],
          },
          overdue: {
            description: 'POC debit Sample: 3,284.50',
            type: 'number',
          },
          phones: {
            description: 'The array of all phones from the POC',
            items: {
              description: 'A single phone from the POC',
              properties: {
                phone_number: {
                  description: 'Phone number. Sample: 1136721420',
                  type: ['string', 'null'],
                },
                phone_score: {
                  description: 'The score in total points of the phone called. Sample: 20',
                  type: ['integer', 'null'],
                },
                phone_source: {
                  description:
                    'Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]',
                  type: ['array', 'null'],
                },
              },
              required: ['phone_number', 'phone_score', 'phone_source'],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          points_lost: {
            description: 'Reward points the POC lost by using the order taking. Sample: 347',
            type: ['number', 'null'],
          },
          reason: {
            description: 'Selected option as the reason. Samples: BEES issues',
            type: ['string', 'null'],
          },
          reason_description: {
            description:
              'Free text comment on the reason field. Samples: Tive um problema com o app',
            type: ['string', 'null'],
          },
          redemption_combos_available: {
            description: 'comboId of the items available for redemption to that POC',
            type: ['array', 'null'],
          },
          retry_call_time: {
            description:
              'When typification is "call again later" time frame for the new call trial. Samples: 30 min later',
            type: ['string', 'null'],
          },
          reward_point_balance: {
            description: 'current balance of reward points for the POC. Sample: 9,600',
            type: ['integer', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          service_duration: {
            description:
              'The duration of the service in seconds from the moment a new POC is got from the list. Samples: 30, 240',
            type: ['number', 'null'],
          },
          tags: {
            description:
              'Tags associated with the POC. Samples: #LoginFailureNoCheckout7Days, #DiaB',
            type: ['array', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
          typification: {
            description: 'Typification in the given context. Samples: NoAnswer, UpliftOnCall',
            type: ['string', 'null'],
          },
          typification_description: {
            description: 'Typification free text in the given context. Samples: Não tive resposta',
            type: ['string', 'null'],
          },
          vendor_id: {
            description: 'Vendor ID. Sample: BRF',
            id: '/properties/properties/properties/vendor_id',
            type: ['string', 'null'],
          },
        },
        required: [
          'is_typed',
          'service_duration',
          'reason',
          'phones',
          'is_searched_by_poc',
          'poc_id',
          'call_duration',
          'vendor_id',
          'is_resumed',
          'campaign_types_assigned',
          'overdue',
          'analysis_time',
          'poc_segment',
          'total_gap',
          'typification',
          'DDC',
          'accumulative_discount',
          'is_promotion_offered',
          'campaigns_assigned',
          'points_lost',
          'is_cti_connected',
          'retry_call_time',
          'typification_description',
          'call_id',
          'time_of_day',
          'is_pasted',
          'total_vol_gap',
          'reward_point_balance',
          'tags',
          'poc_subsegment',
          'DDC_name',
          'currency',
          'reason_description',
          'credit_available',
          'call_trigger',
          'campaign_tags_assigned',
          'is_to_recommend_used',
          'screen_name',
          'redemption_combos_available',
          'next_action',
          'call_attempts',
          'country',
          'missions',
          'is_b2b_trained',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Ended',
    type: 'object',
  };
  const message = {
    event: 'Call Ended',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Ended', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * > user clicks on "Start Call" button on Gap & Status screen, but action returns an error
>user clicks on "Start Call" button in the CTI modal, but action returns an error
 *
 * @param {CallFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '> user clicks on "Start Call" button on Gap & Status screen, but action returns an error\n>user clicks on "Start Call" button in the CTI modal, but action returns an error',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          is_pasted: {
            description:
              'if the number dialed was pasted into the "dial a new number" field. It\'s a boolean',
            type: ['boolean', 'null'],
          },
          is_resumed: {
            description: "If that service was paused and resumed. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_typed: {
            description: "if the agent has dialed the POC phone number manually. It's a boolean",
            type: ['boolean', 'null'],
          },
          phone_rank: {
            description:
              'Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2',
            type: ['integer', 'null'],
          },
          phone_score: {
            description: 'The score in total points of the phone called. Sample: 20',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_potential: {
            description: 'the potential the POC has to be uplifted. Sample: 5',
            type: ['integer', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
        },
        required: [
          'error_message',
          'time_of_day',
          'is_pasted',
          'total_gap',
          'screen_section',
          'call_id',
          'error_type',
          'poc_segment',
          'is_resumed',
          'country',
          'poc_potential',
          'poc_id',
          'poc_subsegment',
          'currency',
          'phone_rank',
          'phone_score',
          'total_vol_gap',
          'DDC',
          'is_typed',
          'call_trigger',
          'screen_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Failed',
    type: 'object',
  };
  const message = {
    event: 'Call Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User click on "Insert" GAP Reason and successfully registers the reason
 *
 * @param {CallGapReasonRegistered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callGapReasonRegistered(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User click on "Insert" GAP Reason and successfully registers the reason',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Gap Reason Registered',
    type: 'object',
  };
  const message = {
    event: 'Call Gap Reason Registered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Gap Reason Registered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * A new POC is got from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected.
 *
 * @param {CallGetNextPoc} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callGetNextPoc(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A new POC is got from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
          vendor_id: {
            description: 'Vendor ID. Sample: BRF',
            id: '/properties/properties/properties/vendor_id',
            type: ['string', 'null'],
          },
        },
        required: [
          'total_vol_gap',
          'currency',
          'poc_id',
          'total_gap',
          'call_id',
          'time_of_day',
          'screen_name',
          'country',
          'call_trigger',
          'screen_section',
          'vendor_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Get Next Poc',
    type: 'object',
  };
  const message = {
    event: 'Call Get Next Poc',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Get Next Poc', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User tries to get a new POC from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected, but the action fails
 *
 * @param {CallGetNextPocFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callGetNextPocFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User tries to get a new POC from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected, but the action fails',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
        },
        required: ['screen_name', 'error_message', 'screen_section', 'error_type', 'time_of_day'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Get Next Poc Failed',
    type: 'object',
  };
  const message = {
    event: 'Call Get Next Poc Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Get Next Poc Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the button “Reconnect” after his connection with CTI fails and the call starts
 *
 * @param {CallReconnect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callReconnect(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the button “Reconnect” after his connection with CTI fails and the call starts',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
        },
        required: ['screen_name', 'call_id', 'country', 'time_of_day'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Reconnect',
    type: 'object',
  };
  const message = {
    event: 'Call Reconnect',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Reconnect', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * > user clicks on "Start Call" button on Gap & Status screen
> user clicks on "Call" button in the CTI modal
 *
 * @param {CallStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '> user clicks on "Start Call" button on Gap & Status screen\n> user clicks on "Call" button in the CTI modal',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          is_cti_connected: {
            description: 'If the CTI was connected during the call. True if yes False if not',
            type: ['boolean', 'null'],
          },
          is_pasted: {
            description:
              'if the number dialed was pasted into the "dial a new number" field. It\'s a boolean',
            type: ['boolean', 'null'],
          },
          is_typed: {
            description: "if the agent has dialed the POC phone number manually. It's a boolean",
            type: ['boolean', 'null'],
          },
          phone_rank: {
            description:
              'Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2',
            type: ['integer', 'null'],
          },
          phone_score: {
            description: 'The score in total points of the phone called. Sample: 20',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_potential: {
            description: 'the potential the POC has to be uplifted. Sample: 5',
            type: ['integer', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
          vendor_id: {
            description: 'Vendor ID. Sample: BRF',
            id: '/properties/properties/properties/vendor_id',
            type: ['string', 'null'],
          },
        },
        required: [
          'call_id',
          'country',
          'phone_rank',
          'screen_section',
          'total_vol_gap',
          'is_cti_connected',
          'DDC',
          'vendor_id',
          'poc_segment',
          'time_of_day',
          'poc_potential',
          'is_pasted',
          'is_typed',
          'screen_name',
          'call_trigger',
          'currency',
          'poc_subsegment',
          'phone_score',
          'total_gap',
          'poc_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Call Started',
    type: 'object',
  };
  const message = {
    event: 'Call Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on tab menu on Call section to change pages
 *
 * @param {CallTabMenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callTabMenuInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on tab menu on Call section to change pages',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
          tab_name: {
            description: 'Name of the tab in local language.  Samples: GAP & Status, Pedidos',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'tab_label', 'tab_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Call Tab Menu Interaction',
    type: 'object',
  };
  const message = {
    event: 'Call Tab Menu Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call Tab Menu Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * "> user collapses or expands POC details section
 > user clicks on ""insert"" GAP reason
 > user toggles ""GAP only"" to filter lists
 > user selects a filter option for the lists
 > user clicks to expand or colapse last orders
 > user toggles tabs ""Gap & Status, B2B Trainer, Orders"""
 *
 * @param {CallUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '"> user collapses or expands POC details section\n > user clicks on ""insert"" GAP reason\n > user toggles ""GAP only"" to filter lists\n > user selects a filter option for the lists\n > user clicks to expand or colapse last orders\n > user toggles tabs ""Gap & Status, B2B Trainer, Orders"""',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          mission: {
            description: 'Current mission. Sample: Marketplace Activation',
            id: '/properties/properties/properties/mission',
            type: ['string', 'null'],
          },
          order_date: {
            description: 'Date when the order was placed. Sample: 2022-05-06',
            id: '/properties/properties/properties/order_date',
            type: ['string', 'null'],
          },
          position: {
            description: 'The position of the item in the list, starting by 1. Sample: 7',
            id: '/properties/properties/properties/position',
            type: ['integer', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: [
          'order_date',
          'filter_option',
          'screen_name',
          'button_name',
          'button_label',
          'mission',
          'screen_section',
          'position',
          'call_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Call UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Call UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Call UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on a dropdown menu
 *
 * @param {CallUxDropdownMenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callUxDropdownMenuInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on a dropdown menu',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          menu_label: {
            description: 'Label of the dropdown menu. Samples: Product Label',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'menu_label', 'screen_section', 'filter_option'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Call UX Dropdown Menu Interaction',
    type: 'object',
  };
  const message = {
    event: 'Call UX Dropdown Menu Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Call UX Dropdown Menu Interaction',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * > User clicks on the ‘campaign’ tab option;
> User toggles between campaigns using the dropdown menu
 *
 * @param {CampaignViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '> User clicks on the ‘campaign’ tab option;\n> User toggles between campaigns using the dropdown menu',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card\n',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          campaign_name: {
            description: 'Name of the current campaign being viewed. Sample: Summer Favourites\n',
            type: ['string', 'null'],
          },
          campaign_tag: {
            description: 'Tag of the campaign being viewed. Sample: #b2opitstop',
            type: ['string', 'null'],
          },
          campaign_type: {
            description: 'Type of the campaign. Samples: B2O, Rewards',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: [
          'campaign_type',
          'call_id',
          'poc_id',
          'button_label',
          'campaign_tag',
          'campaign_name',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Campaign Viewed',
    type: 'object',
  };
  const message = {
    event: 'Campaign Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaign Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Add" button in the campaigns' products section and the item is added to the order cart
 *
 * @param {CampaignsProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignsProductAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Add" button in the campaigns\' products section and the item is added to the order cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: "If the product is a promotion/deal. It's a boolean",
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'call_id',
          'combo_id',
          'is_out_of_stock',
          'is_promotion',
          'name',
          'poc_id',
          'quantity',
          'sku',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Campaigns Product Added',
    type: 'object',
  };
  const message = {
    event: 'Campaigns Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Campaigns Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User alters the quantity of a product in the campaigns products tab
 *
 * @param {CampaignsProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignsProductQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User alters the quantity of a product in the campaigns products tab',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Campaigns Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Campaigns Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Campaigns Product Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on "Add to cart" button in the section Offerings > Deals > Combos and a combo is added to cart
 *
 * @param {ComboAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function comboAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Add to cart" button in the section Offerings > Deals > Combos and a combo is added to cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['poc_id', 'country', 'combo_id', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Combo Added',
    type: 'object',
  };
  const message = {
    event: 'Combo Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Combo Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on “Update note” inside the customer notes component and the message is updated.
 *
 * @param {CustomerNoteUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerNoteUpdated(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on “Update note” inside the customer notes component and the message is updated.',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id', 'screen_name', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer Note Updated',
    type: 'object',
  };
  const message = {
    event: 'Customer Note Updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Customer Note Updated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User expands/collapses any component inside Customer's Page Last Contacts
 *
 * @param {CustomerUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "User expands/collapses any component inside Customer's Page Last Contacts",
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card\n',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          typification: {
            description: 'Typification in the given context. Samples: NoAnswer, UpliftOnCall',
            type: ['string', 'null'],
          },
        },
        required: ['typification', 'call_id', 'button_name', 'button_label', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Customer UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Customer UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Customer UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on a date from the delivery date calendar
 *
 * @param {DeliveryDateChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryDateChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on a date from the delivery date calendar',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          is_alternative_date: {
            description:
              "If the delivery date chosen by the agent is an alternative. It's a boolean",
            type: ['boolean', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id', 'is_alternative_date'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Delivery Date Changed',
    type: 'object',
  };
  const message = {
    event: 'Delivery Date Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Delivery Date Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters a single empty group quantity in the "empties" tab
 *
 * @param {EmptiesQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emptiesQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user alters a single empty group quantity in the "empties" tab',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          empties_balance: {
            description: 'Total balance of the empty. Sample: 7\n',
            type: ['integer', 'null'],
          },
          empties_required: {
            description: 'Total empties required. Sample: 15',
            type: ['integer', 'null'],
          },
          empty_sku: {
            description: 'SKU of the empty. Sample: 2000162',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            type: ['integer', 'null'],
          },
        },
        required: [
          'quantity',
          'empties_balance',
          'empties_required',
          'call_id',
          'poc_id',
          'empty_sku',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Empties Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Empties Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Empties Quantity Edited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * An error pop-up is displayed to the user or an error screen is loaded
 *
 * @param {ErrorMessageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorMessageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'An error pop-up is displayed to the user or an error screen is loaded',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          DDC_name: {
            description: 'Name of the Direct Distribution Center',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          credit_available: {
            description:
              'Amount of credit available to that POC at the start of the service. Samples: 1233.99',
            type: ['number', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          is_all_products: {
            description:
              "If all of the products from the list were affected by SKU errors. True for all products or False if not all products. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_resumed: {
            description: "If that service was paused and resumed. It's a boolean",
            type: ['boolean', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
        },
        required: [
          'poc_subsegment',
          'DDC',
          'error_type',
          'poc_segment',
          'credit_available',
          'time_of_day',
          'screen_name',
          'country',
          'screen_section',
          'call_id',
          'poc_id',
          'currency',
          'call_trigger',
          'DDC_name',
          'is_all_products',
          'is_resumed',
          'total_vol_gap',
          'total_gap',
          'error_message',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Error Message Viewed',
    type: 'object',
  };
  const message = {
    event: 'Error Message Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Error Message Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User creates a new password and clicks on “submit”.
 *
 * @param {ForgotPasswordCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User creates a new password and clicks on “submit”.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
          password_displayed: {
            description:
              "Was the user displaying their password in plaintext when they completed the action. It's a boolean",
            type: ['boolean', 'null'],
          },
        },
        required: ['login_country', 'password_displayed'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Forgot Password Completed',
    type: 'object',
  };
  const message = {
    event: 'Forgot Password Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Forgot Password Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "forgot your password" button in the login screen
 *
 * @param {ForgotPasswordStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordStarted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "forgot your password" button in the login screen',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
        },
        required: ['login_country'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Forgot Password Started',
    type: 'object',
  };
  const message = {
    event: 'Forgot Password Started',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Forgot Password Started', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on “submit” to receive an one-time password.
 *
 * @param {ForgotPasswordSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordSubmitted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on “submit” to receive an one-time password.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
        },
        required: ['login_country'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Forgot Password Submitted',
    type: 'object',
  };
  const message = {
    event: 'Forgot Password Submitted',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Forgot Password Submitted', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User inserts the one-time password he received by email and clicks on “submit”
 *
 * @param {ForgotPasswordVerificationCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordVerificationCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User inserts the one-time password he received by email and clicks on “submit”',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
        },
        required: ['login_country'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Forgot Password Verification Completed',
    type: 'object',
  };
  const message = {
    event: 'Forgot Password Verification Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Forgot Password Verification Completed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on any button inside header section on home screen
 *
 * @param {HomeHeaderInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function homeHeaderInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on any button inside header section on home screen',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: english, spanish',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'button_name', 'button_selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Home Header Interaction',
    type: 'object',
  };
  const message = {
    event: 'Home Header Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Home Header Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between Grow ID, ERP ID, Tax ID and Liquor License inside 360º View
 *
 * @param {IdTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function idTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User toggles between Grow ID, ERP ID, Tax ID and Liquor License inside 360º View',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'ID Tab Interaction',
    type: 'object',
  };
  const message = {
    event: 'ID Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('ID Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Add" button in either promo and combo sections and the item is added to the order cart
 *
 * @param {LocalProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Add" button in either promo and combo sections and the item is added to the order cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: "If the product is a promotion/deal. It's a boolean",
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          minimum_quantity: {
            description: 'number of the minimum quantity suggested for that product/combo',
            id: '/properties/properties/properties/minimum_quantity',
            type: ['string', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'call_id',
          'quantity',
          'sku',
          'combo_id',
          'is_out_of_stock',
          'minimum_quantity',
          'is_promotion',
          'name',
          'poc_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Product Added',
    type: 'object',
  };
  const message = {
    event: 'Local Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * the products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)
 *
 * @param {LocalProductsLoaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'the products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          local_mission: {
            description: 'title of the local mission (New Michelob Ultra Longneck)',
            id: '/properties/properties/properties/local_mission',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          products: {
            description: 'The product array, containing all nested props',
            id: '/properties/properties/properties/products',
            items: {
              description: 'An individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                base_price: {
                  description: 'original price. Sample: 1179.84',
                  id: '/properties/properties/properties/products/items/properties/base_price',
                  type: ['number', 'null'],
                },
                brand: {
                  description: 'name of brand. Sample: Brahma',
                  id: '/properties/properties/properties/products/items/properties/brand',
                  type: ['string', 'null'],
                },
                category: {
                  description: 'category of the item. Sample: Beer',
                  id: '/properties/properties/properties/products/items/properties/category',
                  type: ['string', 'null'],
                },
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                image_url: {
                  description:
                    'image URL of the product. Sample: https://www.socioscerveceria.com.do/media/catalog/product/m/a/malta_morena_8_onz_pla_stica_-_fardos_24_unidades_en_6pack_malta_escolar_.png',
                  id: '/properties/properties/properties/products/items/properties/image_url',
                  type: ['string', 'null'],
                },
                is_focus_sku: {
                  description: "if the product was set as a Focus SKU. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_focus_sku',
                  type: ['boolean', 'null'],
                },
                is_out_of_stock: {
                  description: "if the product is out of stock. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: "If the product is a promotion/deal. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                is_recommended: {
                  description: "if the product is recommended. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_recommended',
                  type: ['boolean', 'null'],
                },
                name: {
                  description:
                    'name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Uni',
                  id: '/properties/properties/properties/products/items/properties/name',
                  type: ['string', 'null'],
                },
                packaging: {
                  description: "the product's packaging volume. Sample: 450.0ML",
                  id: '/properties/properties/properties/products/items/properties/packaging',
                  type: ['string', 'null'],
                },
                position: {
                  description: "the product's position in the list. Sample: 7",
                  id: '/properties/properties/properties/products/items/properties/position',
                  type: ['integer', 'null'],
                },
                price: {
                  description:
                    'price at which SKU is being sold (may differ from base_price for deals and promotions). Sample: 998.65',
                  id: '/properties/properties/properties/products/items/properties/price',
                  type: ['number', 'null'],
                },
                priority: {
                  description:
                    'product priority, starting by the most prioritized as 1. Sample: 17',
                  id: '/properties/properties/properties/products/items/properties/priority',
                  type: ['integer', 'null'],
                },
                promotion_type: {
                  description: 'the promotion type of the item. Sample: stepped discount',
                  id: '/properties/properties/properties/products/items/properties/promotion_type',
                  type: ['string', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
              },
              required: [
                'base_price',
                'brand',
                'category',
                'promotion_type',
                'image_url',
                'name',
                'packaging',
                'position',
                'price',
                'sku',
                'priority',
                'is_out_of_stock',
                'is_focus_sku',
                'is_recommended',
                'is_promotion',
                'combo_id',
              ],
              type: 'object',
            },
            type: ['array', 'null'],
          },
          sponsored_mission: {
            description: 'title of the sponsored mission. Sample: New Michelob Ultra Longneck',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['local_mission', 'poc_id', 'sponsored_mission', 'products', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Products Loaded',
    type: 'object',
  };
  const message = {
    event: 'Local Products Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Local Products Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters the quantity of a product in the local mission tab
 *
 * @param {LocalProductsQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user alters the quantity of a product in the local mission tab',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Local Products Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Local Products Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Local Products Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on the “enter” button in the login screen, but action returns an error
 *
 * @param {LoginFailed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginFailed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the “enter” button in the login screen, but action returns an error',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
          password_displayed: {
            description:
              "Was the user displaying their password in plaintext when they completed the action. It's a boolean",
            type: ['boolean', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
        },
        required: [
          'login_country',
          'time_of_day',
          'error_type',
          'error_message',
          'password_displayed',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Login Failed',
    type: 'object',
  };
  const message = {
    event: 'Login Failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Login Failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Sent when login succeeded
 *
 * @param {LoginSuccessful} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSuccessful(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Sent when login succeeded',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
          time_of_day: {
            description:
              'The hour of day the event occurred (drill down to hour only). Sample: 15, 09',
            type: ['integer', 'null'],
          },
        },
        required: ['login_country', 'time_of_day'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Login Successful',
    type: 'object',
  };
  const message = {
    event: 'Login Successful',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Login Successful', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Add to cart" button in the marketplace recommended products list and the item is added to the order cart
 *
 * @param {MarketplaceProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function marketplaceProductAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Add to cart" button in the marketplace recommended products list and the item is added to the order cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          is_focus_sku: {
            description: "If the product was set as a Focus SKU. It's a boolean",
            id: '/properties/properties/properties/is_focus_sku',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: "If the product is a promotion/deal. It's a boolean",
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          is_recommended: {
            description: "If the product is recommended. It's a boolean\n",
            id: '/properties/properties/properties/is_recommended',
            type: ['boolean', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          priority: {
            description: 'Product priority, starting by the most prioritized as 1. Sample: 17',
            id: '/properties/properties/properties/priority',
            type: ['integer', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'poc_id',
          'name',
          'quantity',
          'sku',
          'is_out_of_stock',
          'call_id',
          'is_focus_sku',
          'is_promotion',
          'is_recommended',
          'priority',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Marketplace Product Added',
    type: 'object',
  };
  const message = {
    event: 'Marketplace Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Marketplace Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User alters the quantity of a marketplace recommended product
 *
 * @param {MarketplaceProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function marketplaceProductQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User alters the quantity of a marketplace recommended product',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Marketplace Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Marketplace Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Marketplace Product Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * The marketplace recommended products list is loaded after user accesses the Marketplace mission tab (it will be fired only if the agent accesses the tab)
 *
 * @param {MarketplaceRecommendedProductsLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function marketplaceRecommendedProductsLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'The marketplace recommended products list is loaded after user accesses the Marketplace mission tab (it will be fired only if the agent accesses the tab)',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          products: {
            description: 'The product array, containing all nested props',
            id: '/properties/properties/properties/products',
            items: {
              description: 'An individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                is_focus_sku: {
                  description: "if the product was set as a Focus SKU. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_focus_sku',
                  type: ['boolean', 'null'],
                },
                is_out_of_stock: {
                  description: "if the product is out of stock. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: "If the product is a promotion/deal. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                is_recommended: {
                  description: "if the product is recommended. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_recommended',
                  type: ['boolean', 'null'],
                },
                priority: {
                  description:
                    'product priority, starting by the most prioritized as 1. Sample: 17',
                  id: '/properties/properties/properties/products/items/properties/priority',
                  type: ['integer', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
              },
              required: [
                'sku',
                'priority',
                'is_out_of_stock',
                'is_focus_sku',
                'is_recommended',
                'is_promotion',
              ],
              type: 'object',
            },
            type: 'array',
          },
        },
        required: ['call_id', 'poc_id', 'products'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Marketplace Recommended Products Loaded',
    type: 'object',
  };
  const message = {
    event: 'Marketplace Recommended Products Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Marketplace Recommended Products Loaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User toggles between missions tabs inside "Missions of the Call" section
 *
 * @param {MissionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User toggles between missions tabs inside "Missions of the Call" section',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Missions Tab Interaction',
    type: 'object',
  };
  const message = {
    event: 'Missions Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Missions Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the “close” button in the NPS modal.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function npsAnswered(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on the “close” button in the NPS modal.',
    labels: {},
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'NPS Answered',
    type: 'object',
  };
  const message = {
    event: 'NPS Answered',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('NPS Answered', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * New offerings list is loaded after user types the query and clicks on "go"
 *
 * @param {NewOfferingsListLoaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newOfferingsListLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'New offerings list is loaded after user types the query and clicks on "go"',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          credit_available: {
            description:
              'Amount of credit available to that POC at the start of the service. Samples: 1233.99',
            type: ['number', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          list_quantity: {
            description: 'Quantity of products listed for the query. Samples: 1, 7, 9',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
        },
        required: [
          'poc_segment',
          'poc_subsegment',
          'credit_available',
          'search_query',
          'call_id',
          'call_trigger',
          'country',
          'poc_id',
          'total_gap',
          'total_vol_gap',
          'currency',
          'list_quantity',
          'filter_option',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'New Offerings List Loaded',
    type: 'object',
  };
  const message = {
    event: 'New Offerings List Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('New Offerings List Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on “Save New Number” inside the new CTI and a new phone is added to the POC
 *
 * @param {NewPhoneAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newPhoneAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on “Save New Number” inside the new CTI and a new phone is added to the POC',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          phone_number: {
            description: 'Phone number. Sample: 1136721420\n',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['poc_id', 'phone_number', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'New Phone Added',
    type: 'object',
  };
  const message = {
    event: 'New Phone Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('New Phone Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between To recommend and Deals inside Offerings
 *
 * @param {OfferingsDropdownMenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offeringsDropdownMenuInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User toggles between To recommend and Deals inside Offerings',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offerings Dropdown Menu Interaction',
    type: 'object',
  };
  const message = {
    event: 'Offerings Dropdown Menu Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Offerings Dropdown Menu Interaction',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart
 *
 * @param {OfferingsProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offeringsProductAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Add" button in either promo and combo sections and the item is added to the order cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          promotions_section: {
            description: 'Name of the promotions tab the user is in. Samples: promotions, combos',
            id: '/properties/properties/properties/promotions_section',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'promotions_section',
          'call_id',
          'combo_id',
          'poc_id',
          'sku',
          'quantity',
          'is_out_of_stock',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offerings Product Added',
    type: 'object',
  };
  const message = {
    event: 'Offerings Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Offerings Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters the quantity of a product in the offerings section
 *
 * @param {OfferingsProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offeringsProductQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user alters the quantity of a product in the offerings section',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Offerings Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Offerings Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Offerings Product Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User interacts with the search field/button in the offerings section
 *
 * @param {OfferingsSearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offeringsSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the offerings section',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'search_query', 'filter_option', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Offerings Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Offerings Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Offerings Search Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * > user expands/collapses an individual promotion/combo details
> user expands/collapses all of the promotions/combos details by clicking on ""expand all
 *
 * @param {OfferingsUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offeringsUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      '> user expands/collapses an individual promotion/combo details\n> user expands/collapses all of the promotions/combos details by clicking on ""expand all',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card\n',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          promotions_section: {
            description: 'Name of the promotions tab the user is in. Samples: promotions, combos',
            type: ['string', 'null'],
          },
        },
        required: ['promotions_section', 'button_label', 'button_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Offerings UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Offerings UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Offerings UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between Order Summary, Shipping Setup and Empties tabs in the cart section
 *
 * @param {OrderCartTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCartTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User toggles between Order Summary, Shipping Setup and Empties tabs in the cart section',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['tab_label', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Order Cart Tab Interaction',
    type: 'object',
  };
  const message = {
    event: 'Order Cart Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Order Cart Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Confirm" in the order confirmation modal and the request is sent to Bees
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Confirm" in the order confirmation modal and the request is sent to Bees',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          accumulative_discount: {
            description: 'Amount of accumulative discount available for the POC. Sample: 1,350.00',
            type: ['number', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          credit_available: {
            description:
              'Amount of credit available to that POC at the start of the service. Samples: 1233.99',
            type: ['number', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          details_free_text: {
            description:
              'Details described in the field. Samples: Shipping address may change, please double check',
            type: ['string', 'null'],
          },
          had_technical_problem: {
            description:
              "if the agent placed the order for the POC due to technical problems on Bees. It's a boolean",
            type: ['boolean', 'null'],
          },
          has_overprice: {
            description:
              'If the POC has overprice charge to be applied on products. True if yes, False if not',
            type: ['boolean', 'null'],
          },
          is_alternative_date: {
            description:
              "If the delivery date chosen by the agent is an alternative. It's a boolean",
            type: ['boolean', 'null'],
          },
          is_alternative_date_available: {
            description:
              "If the POC has the option of alternative delivery dates for delivery. It's a boolean",
            type: ['boolean', 'null'],
          },
          order_id: {
            description: 'The order ID in ERP. Samples: 105',
            type: ['string', 'null'],
          },
          order_reason: {
            description:
              'free text on order confirmation with the reason why the agent took the order',
            type: ['string', 'null'],
          },
          overdue: {
            description: 'POC debit Sample: 3,284.50',
            type: 'number',
          },
          payment_method: {
            description: 'Selected payment method. Samples: Cash, Bank slip',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          points_lost: {
            description: 'Reward points the POC lost by using the order taking. Sample: 347',
            type: ['number', 'null'],
          },
          revenue: {
            description: 'Total cost before tax and shipping. Sample: 6,055.85',
            type: ['number', 'null'],
          },
          shipping_cases: {
            description: 'Number of cases to be delivered. Sample 937',
            type: ['integer', 'null'],
          },
          shipping_pallets: {
            description: 'Number of pallets to be delivered. Sample: 30',
            type: ['integer', 'null'],
          },
          shipping_trucks: {
            description: 'Number of necessary trucks to make the deliver. Sample: 2',
            type: ['integer', 'null'],
          },
          total: {
            description: 'Total cost of the order after discounts and taxes. Sample: 6,035.70',
            type: ['number', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
        },
        required: [
          'poc_segment',
          'payment_method',
          'details_free_text',
          'call_id',
          'accumulative_discount',
          'shipping_trucks',
          'shipping_cases',
          'revenue',
          'poc_subsegment',
          'country',
          'order_id',
          'order_reason',
          'overdue',
          'had_technical_problem',
          'total_vol_gap',
          'is_alternative_date',
          'poc_id',
          'shipping_pallets',
          'currency',
          'credit_available',
          'call_trigger',
          'total_gap',
          'points_lost',
          'total',
          'is_alternative_date_available',
          'has_overprice',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Order Completed',
    type: 'object',
  };
  const message = {
    event: 'Order Completed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Order Completed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User toggles between Order Summary and Shipping Setup in the cart section
 *
 * @param {OrderTakingUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderTakingUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User toggles between Order Summary and Shipping Setup in the cart section',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card\n',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'button_label', 'button_name', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Order Taking UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Order Taking UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Order Taking UX Button Clicked',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user accesses another page in the application
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user accesses another page in the application',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            type: ['string', 'null'],
          },
          path: {
            description: 'Path of the screen. Samples: /call',
            type: ['string', 'null'],
          },
          referrer: {
            description:
              'URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login',
            type: ['string', 'null'],
          },
          search: {
            description: 'The term used in search modules. Samples: Invoices',
            type: ['string', 'null'],
          },
          title: {
            description: 'title of the page displayed to the use. Sample: Gap & Status',
            type: ['string', 'null'],
          },
          url: {
            description:
              'URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call',
            type: ['string', 'null'],
          },
        },
        required: ['name', 'referrer', 'title', 'url', 'path'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Page Viewed',
    type: 'object',
  };
  const message = {
    event: 'Page Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Page Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "did not receive OTP"on the password reset
 *
 * @param {PasswordDidNotReceiveOtp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passwordDidNotReceiveOtp(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "did not receive OTP"on the password reset',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          login_country: {
            description: 'country of the user logging in. Sample: Brazil\n',
            type: ['string', 'null'],
          },
        },
        required: ['login_country'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Password - Did not Receive OTP',
    type: 'object',
  };
  const message = {
    event: 'Password - Did not Receive OTP',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Password - Did not Receive OTP',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on any classification option and the score of that number is updated
 *
 * @param {PhoneClassified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneClassified(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on any classification option and the score of that number is updated',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          phone_classification: {
            description: 'Classification given to the number. Sample: Success\n',
            type: ['string', 'null'],
          },
          phone_number: {
            description: 'Phone number. Sample: 1136721420\n',
            type: ['string', 'null'],
          },
          phone_rank: {
            description:
              'Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2',
            type: ['integer', 'null'],
          },
          phone_source: {
            description:
              'Where the number was sourced from. Sample: ["ERP Phone", "Bees Phone", "Inputted"]\n',
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: [
          'call_id',
          'poc_id',
          'phone_classification',
          'phone_number',
          'phone_rank',
          'phone_source',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Phone Classified',
    type: 'object',
  };
  const message = {
    event: 'Phone Classified',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Phone Classified', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User copies the phone number in the CTI Modal
 *
 * @param {PhoneCopied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneCopied(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User copies the phone number in the CTI Modal',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          phone_rank: {
            description:
              'Index in the list of the current number (starting from 1). The number of the top is the first. Sample: 2',
            type: ['integer', 'null'],
          },
          phone_score: {
            description: 'The score in total points of the phone called. Sample: 20',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id', 'phone_rank', 'phone_score'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Phone Copied',
    type: 'object',
  };
  const message = {
    event: 'Phone Copied',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Phone Copied', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Triggered by the clicks on the button “Save Number
 *
 * @param {PhoneEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Triggered by the clicks on the button “Save Number',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          phone_number: {
            description: 'Phone number. Sample: 1136721420\n',
            id: '/properties/properties/properties/phone_number',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id', 'phone_number'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Phone Edited',
    type: 'object',
  };
  const message = {
    event: 'Phone Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Phone Edited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "finish" in the CTI Modal after changing the POC's phone ranking
 *
 * @param {PhoneRankChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneRankChanged(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on "finish" in the CTI Modal after changing the POC\'s phone ranking',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['poc_id', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Phone Rank Changed',
    type: 'object',
  };
  const message = {
    event: 'Phone Rank Changed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Phone Rank Changed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on "Add to cart" button in the product search results section and the item is added to the order cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on "Add to cart" button in the product search results section and the item is added to the order cart',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            type: ['boolean', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            type: ['string', 'null'],
          },
          original_quantity: {
            description: 'Original quantity of the product when list is loaded',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            type: ['integer', 'null'],
          },
          quantity_per_pallet: {
            description: 'Quantity of the product that can be bundled per pallet (100)',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'currency',
          'sku',
          'poc_id',
          'call_id',
          'quantity',
          'original_quantity',
          'name',
          'country',
          'quantity_per_pallet',
          'is_out_of_stock',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Added',
    type: 'object',
  };
  const message = {
    event: 'Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Product list is loaded after user types the query and clicks on "go"
 *
 * @param {ProductListLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Product list is loaded after user types the query and clicks on "go"',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          list_quantity: {
            description: 'Quantity of products listed for the query. Samples: 1, 7, 9',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: [
          'poc_segment',
          'poc_subsegment',
          'search_query',
          'list_quantity',
          'call_id',
          'currency',
          'country',
          'poc_id',
          'call_trigger',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product List Loaded',
    type: 'object',
  };
  const message = {
    event: 'Product List Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Product List Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User changes product quantity in the search results or order cart lists
 *
 * @param {ProductQuantityEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User changes product quantity in the search results or order cart lists',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          edit_method: {
            description:
              'How was the good quantity edited in cart. Possible values: Arrow or Input or Automatic - Low Stock',
            id: '/properties/properties/properties/edit_method',
            type: ['string', 'null'],
          },
          is_combo: {
            description: 'if the item is a combo. True for yes, False for no',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            type: ['boolean', 'null'],
          },
          original_quantity: {
            description: 'Original quantity of the product when list is loaded',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            type: ['integer', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: [
          'call_id',
          'is_combo',
          'quantity',
          'edit_method',
          'currency',
          'original_quantity',
          'country',
          'screen_section',
          'is_out_of_stock',
          'poc_id',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Product Quantity Edited', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on the trash bin icon in the order cart section and removes a product or all the products are removed by the click on "clear cart list"
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on the trash bin icon in the order cart section and removes a product or all the products are removed by the click on "clear cart list"',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          is_combo: {
            description: 'if the item is a combo. True for yes, False for no',
            type: ['boolean', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            type: ['boolean', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            type: ['string', 'null'],
          },
          original_quantity: {
            description: 'Original quantity of the product when list is loaded',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            type: ['integer', 'null'],
          },
          remove_method: {
            description:
              'How was the good removed from cart. Possible values: Remove Icon or Automatic - Out of Stock',
            id: '/properties/properties/properties/remove_method',
            type: ['string', 'null'],
          },
          sku: {
            description: 'Product sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'name',
          'currency',
          'poc_id',
          'remove_method',
          'is_combo',
          'original_quantity',
          'sku',
          'country',
          'call_id',
          'quantity',
          'is_out_of_stock',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Removed',
    type: 'object',
  };
  const message = {
    event: 'Product Removed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Product Removed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the search field/button in the Search Results section
 *
 * @param {ProductSearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the Search Results section',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'button_name', 'search_query'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Product Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Product Search Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User opens "Order Taking" tab for the first time during a service, and the cart pre-fills with all products that have gap.
 *
 * @param {ProductSuggestedOrderLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productSuggestedOrderLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User opens "Order Taking" tab for the first time during a service, and the cart pre-fills with all products that have gap.',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          DDC: {
            description: 'The DDC (Direct Distribution Center) code. Samples: 40, 5',
            type: ['string', 'null'],
          },
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          cart_id: {
            description: "The shopping cart's unique identifier",
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          credit_available: {
            description:
              'Amount of credit available to that POC at the start of the service. Samples: 1233.99',
            type: ['number', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          is_resumed: {
            description: "If that service was paused and resumed. It's a boolean",
            type: ['boolean', 'null'],
          },
          list_quantity: {
            description: 'Quantity of products listed for the query. Samples: 1, 7, 9',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_potential: {
            description: 'the potential the POC has to be uplifted. Sample: 5',
            type: ['integer', 'null'],
          },
          poc_recurrence: {
            description: 'How many times that POC was listed in TLP. Samples: 1, 5, 9',
            type: ['integer', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          total_gap: {
            description: "Total gap to cover. It's a NUMBER. Samples: 15,264.60",
            type: ['number', 'null'],
          },
          total_sku_bought: {
            description: 'Total SKUs bought. Sample: 30',
            type: ['integer', 'null'],
          },
          total_sku_gap: {
            description: 'Total SKU gap. Sample: 70',
            type: ['integer', 'null'],
          },
          total_sku_suggested: {
            description: 'Total SKUs suggested. Sample: 100\n',
            type: ['integer', 'null'],
          },
          total_vol_gap: {
            description: "Total volume gap to cover. It's a NUMBER. Samples: 4,369.70",
            type: ['number', 'null'],
          },
        },
        required: [
          'total_vol_gap',
          'currency',
          'call_trigger',
          'cart_id',
          'poc_recurrence',
          'total_sku_bought',
          'total_sku_gap',
          'DDC',
          'country',
          'poc_potential',
          'total_gap',
          'poc_subsegment',
          'total_sku_suggested',
          'is_resumed',
          'credit_available',
          'poc_segment',
          'call_id',
          'poc_id',
          'list_quantity',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Product Suggested Order Loaded',
    type: 'object',
  };
  const message = {
    event: 'Product Suggested Order Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Product Suggested Order Loaded',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * An error or a warning from the BEES MSs is displayed
 *
 * @param {ProductValidationMessageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productValidationMessageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'An error or a warning from the BEES MSs is displayed',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          message: {
            description: 'Text displayed in the UI. Sample: item not available',
            type: ['string', 'null'],
          },
          message_subtype: {
            description: 'Type of the message. Sample: inventory',
            type: ['string', 'null'],
          },
          message_type: {
            description: 'Type of the message. Samples: warning, error\n',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
        },
        required: ['call_id', 'poc_id', 'message', 'message_subtype', 'message_type'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Product Validation Message Viewed',
    type: 'object',
  };
  const message = {
    event: 'Product Validation Message Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Product Validation Message Viewed',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Promotions list is loaded after user types the query and clicks on "go"
 *
 * @param {PromotionsListLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionsListLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Promotions list is loaded after user types the query and clicks on "go"',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          list_quantity_combo: {
            description: 'Quantity of combo products listed for the query. Sample: 10',
            type: ['integer', 'null'],
          },
          list_quantity_promo: {
            description: 'quantity of promotion products listed for the query. Sample: 05',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: [
          'poc_id',
          'poc_segment',
          'list_quantity_combo',
          'list_quantity_promo',
          'search_query',
          'call_trigger',
          'currency',
          'country',
          'call_id',
          'poc_subsegment',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Promotions List Loaded',
    type: 'object',
  };
  const message = {
    event: 'Promotions List Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Promotions List Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the search field/button in the offerings section
 *
 * @param {PromotionsSearchInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionsSearchInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the offerings section',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
          search_go_method: {
            description: 'Method by which the user concluded the search. Samples: enter, go button',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: [
          'search_go_method',
          'button_label',
          'search_query',
          'filter_option',
          'screen_section',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Promotions Search Interaction',
    type: 'object',
  };
  const message = {
    event: 'Promotions Search Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Promotions Search Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on tab menu on Call section to change pages
 *
 * @param {PromotionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionsTabInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on tab menu on Call section to change pages',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          promotions_section: {
            description: 'Name of the promotions tab the user is in. Samples: promotions, combos',
            type: ['string', 'null'],
          },
          tab_label: {
            description: 'Tab label in English. Samples: Gap & Status, Orders',
            type: ['string', 'null'],
          },
        },
        required: ['promotions_section', 'tab_label'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Promotions Tab Interaction',
    type: 'object',
  };
  const message = {
    event: 'Promotions Tab Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Promotions Tab Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User expands/collapses an individual promotion/combo details.
User expands/collapses all of the promotions/combos details by clicking on "expand all".
 *
 * @param {PromotionsUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionsUxButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User expands/collapses an individual promotion/combo details.\nUser expands/collapses all of the promotions/combos details by clicking on "expand all".',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          promotions_section: {
            description: 'Name of the promotions tab the user is in. Samples: promotions, combos',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
        },
        required: ['button_name', 'button_label', 'promotions_section', 'screen_name'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Promotions UX Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Promotions UX Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Promotions UX Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on any retry button after an error occurs (Ex: click on "reload list" after it fails to load)
 *
 * @param {RetryButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function retryButtonClicked(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicks on any retry button after an error occurs (Ex: click on "reload list" after it fails to load)',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          error_message: {
            description:
              'Text displayed that describes the error Samples: Failed to log in, Please verify your connection',
            type: ['string', 'null'],
          },
          error_type: {
            description: 'Type of error. Samples: wrong password, no connection',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
          screen_section: {
            description: 'Screen Section. Samples: Get Reason',
            type: ['string', 'null'],
          },
        },
        required: ['error_message', 'error_type', 'screen_name', 'screen_section'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Retry Button Clicked',
    type: 'object',
  };
  const message = {
    event: 'Retry Button Clicked',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Retry Button Clicked', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User interacts with the search field/button in the Search by POC section
 *
 * @param {SearchByPocInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchByPocInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User interacts with the search field/button in the Search by POC section',
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Name of the button as is on the code. Sample: update-agents-kpis-card\n',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: ['button_label', 'search_query'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Search by POC Interaction',
    type: 'object',
  };
  const message = {
    event: 'Search by POC Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Search by POC Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicks on any button inside sidebar section
 *
 * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sidebarInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicks on any button inside sidebar section ',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: english, spanish',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'button_label', 'button_name', 'button_selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sidebar Interaction',
    type: 'object',
  };
  const message = {
    event: 'Sidebar Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sidebar Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user clicks on "Add" button in either promo and combo sections and the item is added to the order cart
 *
 * @param {SponsoredProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductAdded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'user clicks on "Add" button in either promo and combo sections and the item is added to the order cart',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          combo_id: {
            description: 'Combo ID. Sample: DT01290007980',
            id: '/properties/properties/properties/combo_id',
            type: ['string', 'null'],
          },
          is_out_of_stock: {
            description: "If the product is out of stock. It's a boolean",
            id: '/properties/properties/properties/is_out_of_stock',
            type: ['boolean', 'null'],
          },
          is_promotion: {
            description: "If the product is a promotion/deal. It's a boolean",
            id: '/properties/properties/properties/is_promotion',
            type: ['boolean', 'null'],
          },
          name: {
            description: 'Name of Product. Sample: Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit',
            id: '/properties/properties/properties/name',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          quantity: {
            description: 'Total quantity of the product added to cart. Sample: 3',
            id: '/properties/properties/properties/quantity',
            type: ['integer', 'null'],
          },
          sku: {
            description: 'Product sku',
            id: '/properties/properties/properties/sku',
            type: ['string', 'null'],
          },
        },
        required: [
          'sku',
          'combo_id',
          'is_out_of_stock',
          'is_promotion',
          'call_id',
          'poc_id',
          'name',
          'quantity',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Product Added',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Product Added',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Product Added', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * user alters the quantity of a product in the sponsored tab
 *
 * @param {SponsoredProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductQuantityEdited(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'user alters the quantity of a product in the sponsored tab',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
        },
        required: ['poc_id', 'call_id'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Sponsored Product Quantity Edited',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Product Quantity Edited',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track(
      'Sponsored Product Quantity Edited',
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * the products list for a single sponsored mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)
 *
 * @param {SponsoredProductsLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductsLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'the products list for a single sponsored mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          products: {
            description: 'The product array, containing all nested props',
            id: '/properties/properties/properties/products',
            items: {
              description: 'An individual product in the list',
              id: '/properties/properties/properties/products/items',
              properties: {
                combo_id: {
                  description: 'combo ID. Sample: DT01290007980\n',
                  id: '/properties/properties/properties/products/items/properties/combo_id',
                  type: ['string', 'null'],
                },
                is_out_of_stock: {
                  description: "if the product is out of stock. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_out_of_stock',
                  type: ['boolean', 'null'],
                },
                is_promotion: {
                  description: "If the product is a promotion/deal. It's a boolean",
                  id: '/properties/properties/properties/products/items/properties/is_promotion',
                  type: ['boolean', 'null'],
                },
                sku: {
                  description: 'product SKU. Sample: ABIC001431',
                  id: '/properties/properties/properties/products/items/properties/sku',
                  type: ['string', 'null'],
                },
              },
              required: ['sku', 'is_out_of_stock', 'is_promotion', 'combo_id'],
              type: 'object',
            },
            type: 'array',
          },
          sponsored_mission: {
            description: 'title of the sponsored mission. Sample: New Michelob Ultra Longneck',
            id: '/properties/properties/properties/sponsored_mission',
            type: ['string', 'null'],
          },
        },
        required: ['products', 'call_id', 'poc_id', 'sponsored_mission'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Sponsored Products Loaded',
    type: 'object',
  };
  const message = {
    event: 'Sponsored Products Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Sponsored Products Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * To recommend list is loaded after user types the query and clicks on "go"
 *
 * @param {ToRecommendListLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function toRecommendListLoaded(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'To recommend list is loaded after user types the query and clicks on "go"',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            type: ['string', 'null'],
          },
          call_trigger: {
            description: 'What triggered the call. Samples: no order, out of parameter',
            type: ['string', 'null'],
          },
          country: {
            description: 'Country of the POC. Samples: Brazil',
            type: ['string', 'null'],
          },
          currency: {
            description: 'ISO currency - Currency associated with product. Samples: BRL, DOP',
            type: ['string', 'null'],
          },
          filter_option: {
            description: 'Filter selection. Samples: gap only, premium, core',
            type: ['string', 'null'],
          },
          list_quantity: {
            description: 'Quantity of products listed for the query. Samples: 1, 7, 9',
            type: ['integer', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            type: ['string', 'null'],
          },
          poc_segment: {
            description: 'The segment of the POC. Samples: Food Led',
            type: ['string', 'null'],
          },
          poc_subsegment: {
            description: 'The subsegment of the POC. Samples: Cafeteria',
            type: ['string', 'null'],
          },
          search_query: {
            description: 'The text typed in the product search field. Samples: Brahma Pilsen',
            type: ['string', 'null'],
          },
        },
        required: [
          'search_query',
          'list_quantity',
          'filter_option',
          'poc_id',
          'poc_segment',
          'currency',
          'call_id',
          'call_trigger',
          'country',
          'poc_subsegment',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'To Recommend List Loaded',
    type: 'object',
  };
  const message = {
    event: 'To Recommend List Loaded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('To Recommend List Loaded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Sser clicks on any button inside top bar section
 *
 * @param {TopBarInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function topBarInteraction(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Sser clicks on any button inside top bar section ',
    labels: {},
    properties: {
      context: {},
      properties: {
        properties: {
          button_label: {
            description: 'Button label in English. Samples: Reload',
            type: ['string', 'null'],
          },
          button_name: {
            description: 'Button name in local language. Samples: Recarregar',
            type: ['string', 'null'],
          },
          button_selection: {
            description: 'Selected option. Samples: english, spanish',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            type: ['string', 'null'],
          },
        },
        required: ['screen_name', 'button_label', 'button_name', 'button_selection'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Top Bar Interaction',
    type: 'object',
  };
  const message = {
    event: 'Top Bar Interaction',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Top Bar Interaction', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * A warning modal is displayed to the user
 *
 * @param {WarningMessageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function warningMessageViewed(props, options, callback) {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A warning modal is displayed to the user ',
    properties: {
      context: {},
      properties: {
        properties: {
          call_id: {
            description: 'ID of the call. Samples: 12123',
            id: '/properties/properties/properties/call_id',
            type: ['string', 'null'],
          },
          low_stock_skus: {
            description:
              'SKUs that has a lower stock than the quantity selected. Sample: ["0000371",  "0002006"] ',
            id: '/properties/properties/properties/low_stock_skus',
            type: ['array', 'null'],
          },
          out_of_stock_skus: {
            description: 'SKUs that are out of stock Sample: ["0000371",  "0002006"]',
            id: '/properties/properties/properties/out_of_stock_skus',
            type: ['array', 'null'],
          },
          poc_id: {
            description: 'ID of the POC. Samples: 446109',
            id: '/properties/properties/properties/poc_id',
            type: ['string', 'null'],
          },
          screen_name: {
            description: "Name of the screen the user is in. Samples: Agent's KPIs, GAP & Status",
            id: '/properties/properties/properties/screen_name',
            type: ['string', 'null'],
          },
          warning_message: {
            description:
              'Text displayed in the UI that describes the warning. Sample: There are products out of stock inside the cart.',
            id: '/properties/properties/properties/warning_message',
            type: ['string', 'null'],
          },
        },
        required: [
          'out_of_stock_skus',
          'call_id',
          'poc_id',
          'screen_name',
          'warning_message',
          'low_stock_skus',
        ],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    title: 'Warning Message Viewed',
    type: 'object',
  };
  const message = {
    event: 'Warning Message Viewed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track('Warning Message Viewed', props || {}, withTypewriterContext(options), callback);
  }
}
const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * User toggles between the Sales Structure and POC Information tabs inside 360º View
   *
   * @param {I360ViewTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  I360ViewTabInteraction,
  /**
   * User clicks on the update button in the daily results section of the Agent KPIs page
   *
   * @param {AgentKpIsUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  agentKpIsUxButtonClicked,
  /**
   * User copies the login information in the B2B Trainer
   *
   * @param {B2BLoginInformationCopied} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  b2BLoginInformationCopied,
  /**
   * User clicks on "Start" or "use the web version" in the B2B Trainer but action returns an error
   *
   * @param {B2BTrainerFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  b2BTrainerFailed,
  /**
   * User clicks on "Start" or "use the web version" in the B2B Trainer and the emulator is launched.
   *
   * @param {B2BTrainerStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  b2BTrainerStarted,
  /**
   * User takes action after the connection with CTI is lost and the modal is displayed
   *
   * @param {CtiDisconnected} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  ctiDisconnected,
  /**
   * Call End Failed
   *
   * @param {CallEndFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callEndFailed,
  /**
   * User clicks on "Register" end call action with success
   *
   * @param {CallEnded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callEnded,
  /**
     * > user clicks on "Start Call" button on Gap & Status screen, but action returns an error
    >user clicks on "Start Call" button in the CTI modal, but action returns an error
     *
     * @param {CallFailed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  callFailed,
  /**
   * User click on "Insert" GAP Reason and successfully registers the reason
   *
   * @param {CallGapReasonRegistered} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callGapReasonRegistered,
  /**
   * A new POC is got from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected.
   *
   * @param {CallGetNextPoc} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callGetNextPoc,
  /**
   * User tries to get a new POC from the list by clicking on the button “get next POC” on home, "call" on sidebar or by registering a typification with the option “call to next POC” selected, but the action fails
   *
   * @param {CallGetNextPocFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callGetNextPocFailed,
  /**
   * User clicks on the button “Reconnect” after his connection with CTI fails and the call starts
   *
   * @param {CallReconnect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callReconnect,
  /**
     * > user clicks on "Start Call" button on Gap & Status screen
    > user clicks on "Call" button in the CTI modal
     *
     * @param {CallStarted} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  callStarted,
  /**
   * User clicks on tab menu on Call section to change pages
   *
   * @param {CallTabMenuInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callTabMenuInteraction,
  /**
     * "> user collapses or expands POC details section
     > user clicks on ""insert"" GAP reason
     > user toggles ""GAP only"" to filter lists
     > user selects a filter option for the lists
     > user clicks to expand or colapse last orders
     > user toggles tabs ""Gap & Status, B2B Trainer, Orders"""
     *
     * @param {CallUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  callUxButtonClicked,
  /**
   * User clicks on a dropdown menu
   *
   * @param {CallUxDropdownMenuInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callUxDropdownMenuInteraction,
  /**
     * > User clicks on the ‘campaign’ tab option;
    > User toggles between campaigns using the dropdown menu
     *
     * @param {CampaignViewed} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  campaignViewed,
  /**
   * user clicks on "Add" button in the campaigns' products section and the item is added to the order cart
   *
   * @param {CampaignsProductAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignsProductAdded,
  /**
   * User alters the quantity of a product in the campaigns products tab
   *
   * @param {CampaignsProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  campaignsProductQuantityEdited,
  /**
   * User clicks on "Add to cart" button in the section Offerings > Deals > Combos and a combo is added to cart
   *
   * @param {ComboAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  comboAdded,
  /**
   * User clicks on “Update note” inside the customer notes component and the message is updated.
   *
   * @param {CustomerNoteUpdated} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerNoteUpdated,
  /**
   * User expands/collapses any component inside Customer's Page Last Contacts
   *
   * @param {CustomerUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  customerUxButtonClicked,
  /**
   * User clicks on a date from the delivery date calendar
   *
   * @param {DeliveryDateChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deliveryDateChanged,
  /**
   * user alters a single empty group quantity in the "empties" tab
   *
   * @param {EmptiesQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  emptiesQuantityEdited,
  /**
   * An error pop-up is displayed to the user or an error screen is loaded
   *
   * @param {ErrorMessageViewed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorMessageViewed,
  /**
   * User creates a new password and clicks on “submit”.
   *
   * @param {ForgotPasswordCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forgotPasswordCompleted,
  /**
   * User clicks on "forgot your password" button in the login screen
   *
   * @param {ForgotPasswordStarted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forgotPasswordStarted,
  /**
   * User clicks on “submit” to receive an one-time password.
   *
   * @param {ForgotPasswordSubmitted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forgotPasswordSubmitted,
  /**
   * User inserts the one-time password he received by email and clicks on “submit”
   *
   * @param {ForgotPasswordVerificationCompleted} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forgotPasswordVerificationCompleted,
  /**
   * User clicks on any button inside header section on home screen
   *
   * @param {HomeHeaderInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  homeHeaderInteraction,
  /**
   * User toggles between Grow ID, ERP ID, Tax ID and Liquor License inside 360º View
   *
   * @param {IdTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  idTabInteraction,
  /**
   * user clicks on "Add" button in either promo and combo sections and the item is added to the order cart
   *
   * @param {LocalProductAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductAdded,
  /**
   * the products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)
   *
   * @param {LocalProductsLoaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductsLoaded,
  /**
   * user alters the quantity of a product in the local mission tab
   *
   * @param {LocalProductsQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localProductsQuantityEdited,
  /**
   * User clicks on the “enter” button in the login screen, but action returns an error
   *
   * @param {LoginFailed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginFailed,
  /**
   * Sent when login succeeded
   *
   * @param {LoginSuccessful} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginSuccessful,
  /**
   * User clicks on "Add to cart" button in the marketplace recommended products list and the item is added to the order cart
   *
   * @param {MarketplaceProductAdded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  marketplaceProductAdded,
  /**
   * User alters the quantity of a marketplace recommended product
   *
   * @param {MarketplaceProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  marketplaceProductQuantityEdited,
  /**
   * The marketplace recommended products list is loaded after user accesses the Marketplace mission tab (it will be fired only if the agent accesses the tab)
   *
   * @param {MarketplaceRecommendedProductsLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  marketplaceRecommendedProductsLoaded,
  /**
   * User toggles between missions tabs inside "Missions of the Call" section
   *
   * @param {MissionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  missionsTabInteraction,
  /**
   * User clicks on the “close” button in the NPS modal.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  npsAnswered,
  /**
   * New offerings list is loaded after user types the query and clicks on "go"
   *
   * @param {NewOfferingsListLoaded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newOfferingsListLoaded,
  /**
   * User clicks on “Save New Number” inside the new CTI and a new phone is added to the POC
   *
   * @param {NewPhoneAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newPhoneAdded,
  /**
   * User toggles between To recommend and Deals inside Offerings
   *
   * @param {OfferingsDropdownMenuInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offeringsDropdownMenuInteraction,
  /**
   * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart
   *
   * @param {OfferingsProductAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offeringsProductAdded,
  /**
   * user alters the quantity of a product in the offerings section
   *
   * @param {OfferingsProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offeringsProductQuantityEdited,
  /**
   * User interacts with the search field/button in the offerings section
   *
   * @param {OfferingsSearchInteraction} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  offeringsSearchInteraction,
  /**
     * > user expands/collapses an individual promotion/combo details
    > user expands/collapses all of the promotions/combos details by clicking on ""expand all
     *
     * @param {OfferingsUxButtonClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  offeringsUxButtonClicked,
  /**
   * User toggles between Order Summary, Shipping Setup and Empties tabs in the cart section
   *
   * @param {OrderCartTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderCartTabInteraction,
  /**
   * user clicks on "Confirm" in the order confirmation modal and the request is sent to Bees
   *
   * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderCompleted,
  /**
   * User toggles between Order Summary and Shipping Setup in the cart section
   *
   * @param {OrderTakingUxButtonClicked} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  orderTakingUxButtonClicked,
  /**
   * When the user accesses another page in the application
   *
   * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pageViewed,
  /**
   * User clicks on "did not receive OTP"on the password reset
   *
   * @param {PasswordDidNotReceiveOtp} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  passwordDidNotReceiveOtp,
  /**
   * User clicks on any classification option and the score of that number is updated
   *
   * @param {PhoneClassified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneClassified,
  /**
   * User copies the phone number in the CTI Modal
   *
   * @param {PhoneCopied} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneCopied,
  /**
   * Triggered by the clicks on the button “Save Number
   *
   * @param {PhoneEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneEdited,
  /**
   * User clicks on "finish" in the CTI Modal after changing the POC's phone ranking
   *
   * @param {PhoneRankChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneRankChanged,
  /**
   * User clicks on "Add to cart" button in the product search results section and the item is added to the order cart
   *
   * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productAdded,
  /**
   * Product list is loaded after user types the query and clicks on "go"
   *
   * @param {ProductListLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productListLoaded,
  /**
   * User changes product quantity in the search results or order cart lists
   *
   * @param {ProductQuantityEdited} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productQuantityEdited,
  /**
   * User clicks on the trash bin icon in the order cart section and removes a product or all the products are removed by the click on "clear cart list"
   *
   * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productRemoved,
  /**
   * User interacts with the search field/button in the Search Results section
   *
   * @param {ProductSearchInteraction} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productSearchInteraction,
  /**
   * User opens "Order Taking" tab for the first time during a service, and the cart pre-fills with all products that have gap.
   *
   * @param {ProductSuggestedOrderLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productSuggestedOrderLoaded,
  /**
   * An error or a warning from the BEES MSs is displayed
   *
   * @param {ProductValidationMessageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  productValidationMessageViewed,
  /**
   * Promotions list is loaded after user types the query and clicks on "go"
   *
   * @param {PromotionsListLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  promotionsListLoaded,
  /**
   * User interacts with the search field/button in the offerings section
   *
   * @param {PromotionsSearchInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  promotionsSearchInteraction,
  /**
   * User clicks on tab menu on Call section to change pages
   *
   * @param {PromotionsTabInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  promotionsTabInteraction,
  /**
     * User expands/collapses an individual promotion/combo details.
    User expands/collapses all of the promotions/combos details by clicking on "expand all".
     *
     * @param {PromotionsUxButtonClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  promotionsUxButtonClicked,
  /**
   * User clicks on any retry button after an error occurs (Ex: click on "reload list" after it fails to load)
   *
   * @param {RetryButtonClicked} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  retryButtonClicked,
  /**
   * User interacts with the search field/button in the Search by POC section
   *
   * @param {SearchByPocInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchByPocInteraction,
  /**
   * User clicks on any button inside sidebar section
   *
   * @param {SidebarInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sidebarInteraction,
  /**
   * user clicks on "Add" button in either promo and combo sections and the item is added to the order cart
   *
   * @param {SponsoredProductAdded} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredProductAdded,
  /**
   * user alters the quantity of a product in the sponsored tab
   *
   * @param {SponsoredProductQuantityEdited} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredProductQuantityEdited,
  /**
   * the products list for a single sponsored mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default)
   *
   * @param {SponsoredProductsLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredProductsLoaded,
  /**
   * To recommend list is loaded after user types the query and clicks on "go"
   *
   * @param {ToRecommendListLoaded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  toRecommendListLoaded,
  /**
   * Sser clicks on any button inside top bar section
   *
   * @param {TopBarInteraction} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  topBarInteraction,
  /**
   * A warning modal is displayed to the user
   *
   * @param {WarningMessageViewed} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  warningMessageViewed,
};
export default new Proxy(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method];
    }
    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1ge6f8BRKlgMRZr1aE7KUG71vOS`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
