import { forwardRef } from 'react';
import { Svg } from './Icon.styles';
import { IconProps } from './IconProps.types';

const CallIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'large', fill, ...rest }, forwardedRef): JSX.Element => (
    <Svg viewBox="-2 -5 24 24" ref={forwardedRef} size={size} {...rest} data-testid="CallIcon">
      <path
        fill={fill}
        d="M15.93 12.75l-.08-.24c-.244-.614-.747-1.09-1.375-1.3l-2.1-.574c-.641-.14-1.31.036-1.8.47l-.762.762c-2.768-.75-4.93-2.913-5.681-5.682l.761-.761c.434-.491.61-1.16.471-1.8l-.573-2.101C4.581.896 4.105.393 3.491.148l-.24-.08c-.626-.172-1.296-.014-1.779.42L.334 1.626C.17 1.862.058 2.129 0 2.41c-.041 3.616 1.377 7.096 3.934 9.654 2.55 2.55 6.018 3.967 9.623 3.935.293-.05.571-.163.817-.33l1.139-1.138c.432-.483.59-1.153.418-1.778l-.002-.004z"
      />
    </Svg>
  )
);

export default CallIcon;
