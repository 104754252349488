import { makeStyles, Theme } from '@material-ui/core';

const ListOptions = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '55px',
    backgroundColor: theme.palette.grey[400],
  },
  container: {
    width: '100%',
    display: 'flex',
    borderRadius: 0,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuIcon: {
    minWidth: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default ListOptions;
