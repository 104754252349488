import palette from './Palette';

export default {
  MuiCssBaseline: {
    '@global': {
      '*': {
        fontFamily: 'Work Sans',
      },
      '::-webkit-scrollbar': {
        width: '8px',
      },
      '::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px transparent',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#939191',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#939191',
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: '5px 15px 10px 40px',
    },
  },
  MuiTypography: {
    h4: {
      color: `${palette.text.secondary}`,
    },
  },
  MuiTableCell: {
    body: {
      backgroundColor: '#F3F3F3',
      color: `${palette.text.secondary}`,
    },
    footer: {
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'sticky',
    } as const,

    sizeSmall: {
      padding: '7px',
      borderBottom: `1px solid ${palette.grey[100]}`,
    },
  },
  MuiButton: {
    outlinedPrimary: {
      borderColor: `${palette.primary.main}`,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    containedPrimary: {
      color: '#FFFFFF',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiInput: {
    fontFamily: 'Work Sans',
    underline: {
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
      '&:after': {
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
    },
  },
  MuiInputBase: {
    root: {
      fontFamily: 'Work Sans',
      color: palette.text.secondary,
      width: '100%',
      '& fieldset': {
        borderRadius: '2px',
        borderColor: '#B6B6B6',
      },
      '& input': {
        '&::placeholder': {
          color: '#BDBDBD',
        },
      },
    },
  },
  MuiTextField: {
    root: {
      '& div': {
        '& input': {
          paddingTop: '5px',
          paddingBottom: '5px',
          height: '20px',
        },
        '& fieldset': {
          borderRadius: '3px 0px 0px 3px',
        },
        paddingRight: '0px',
      },
    },
  },
  MuiAlert: {
    message: {
      fontWeight: 500,
    },
    standardSuccess: {
      color: 'white',
      backgroundColor: palette.success.main,
      '& .hive-MuiAlert-icon': {
        color: 'white',
      },
    },
    standardError: {
      color: 'white',
      backgroundColor: palette.error.main,
      '& .hive-MuiAlert-icon': {
        color: 'white',
      },
    },
  },
  MuiButtonBase: {
    root: {
      borderRadius: '30px',
    },
  },
  MuiIconButton: {
    root: {
      color: palette.primary.main,
      padding: 0,
    },
    label: {
      padding: '1px',
    },
  },
  MuiPickersBasePicker: {
    pickerViewLandscape: {
      '& div': {
        width: '150px',
      },
    },
    pickerView: {
      border: 'solid 1px #dedede',
      borderRadius: '3px',
      minWidth: '170px',
      maxWidth: '170px',
      minHeight: '170px',
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      width: '170px',
      marginTop: '10px',
    },
    daysHeader: {
      width: '170px',
      '& span': {
        fontSize: '10px',
      },
    },
    iconButton: {
      height: '17px',
      width: '17px',
      padding: '1px',
      color: '#7c7c7c',
      '& span': {
        height: '17px',
        width: '17px',
        '& svg': {
          height: '17px',
          width: '17px',
        },
      },
    },
    transitionContainer: {
      height: '14px',
      '& p': {
        fontWeight: 600,
        fontSize: '10px',
      },
    },
  },
  MuiPickersDay: {
    day: {
      color: '#525252',
      width: '17px',
      height: '17px',
      '& span p': {
        fontSize: '11px',
        fontWeight: 900,
      },
    },
    daySelected: {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      borderRadius: '3px',
      '&:hover': {
        backgroundColor: palette.grey[100],
      },
    },
    dayDisabled: {
      color: '#d0d0d0',
    },
    current: {
      color: '',
    },
  },
  MuiPickersSlideTransition: {
    transitionContainer: {
      width: '150px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: '120px',
      marginTop: '8px',
    },
  },
  MuiCardHeader: {
    action: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: 0,
      marginRight: 0,
    },
  },
};
